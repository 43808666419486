import axios from 'axios';
import React, { FunctionComponent, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Image from '../../../interfaces/Image';
import UploadComponent from './UploadComponent';

interface Props {
  componentId: number;
  thisfile: Image;
  browseImage: (addImage: Image) => void;
  removeImageItem?: (removeImage: any) => void;
}

const UploadImage: FunctionComponent<Props> = ({ componentId, thisfile, browseImage, removeImageItem}) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const [fileName, setfileName] = useState('');
  const handleChange = (e: any) => {
    const currentFile = e.target.files[0];
    if (currentFile) {
      if (currentFile.size <= 5500000) {
        setfileName(e.target.files[0].name);
        axios.get(`https://bwt1vir710.execute-api.eu-north-1.amazonaws.com/uploads`).then((res: any) => {
          const addImageItem = {
            name: currentFile.name,
            imageUrl: res.data.uploadURL,
            imageKey: res.data.Key,
            fileObject: currentFile,
            componentId: componentId,
            mainImage: componentId === 1
          } as Image;
          browseImage(addImageItem);
        });
      } else {
          toast.error('Please upload a photo with smaller size!');
      }
    }
  };

  return <UploadComponent componentId={componentId} thisfile={thisfile} fileName={fileName} handleChange={handleChange} removeImageItem={removeImageItem} setfileName={setfileName} />;
};

export default UploadImage;
