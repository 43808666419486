import React, { FunctionComponent, useState } from 'react';
//components
import UploadResource from './UploadResource';
import { Resources } from '../../../interfaces/category';
import ModalDialog from './../../shared/Modal';

export type ResourceProps = {
  resourcesList: Resources[];
  setResourcesList: (resourceList: Resources[]) => void;
};

const ResourcesForm: FunctionComponent<ResourceProps> = (props: ResourceProps) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedResource, setSelectedResource] = useState<Resources | null>(null);
  const [resourceName, setResourceName] = useState<string>('');

  const addResourceItem = (addResource: Resources) => {
    let resourceList = [...props.resourcesList];
    // when componentId === 999 then new resource was picked
    // if is another number than 999 => user has replaced some resource
    if (addResource.componentId !== 999) {
      resourceList.splice(addResource.componentId, 1, addResource);
    } else {
      resourceList.push(addResource);
      setShowForm(false);
    }
    props.setResourcesList(resourceList);
  };

  const addResource = () => {
    setShowForm(true);
  };

  const setNewResourceName = () => {
    if (selectedResource && resourceName !== '') {
      const resource = props.resourcesList.find((resource: Resources) => resource.resourceKey === selectedResource.resourceKey);
      if (resource) {
        resource.name = resourceName;
      }
      props.setResourcesList(props.resourcesList);
    }
    setSelectedResource(null);
    setResourceName('');
  };

  const cancelResource = () => {
    setShowForm(false);
  };

  const handleResourceNameAction = (resource: Resources, index: number) => {
    setSelectedResource(resource);
    setResourceName(resource.name);
  };

  const removeResource = (index: number) => {
    let resourceList = [...props.resourcesList];
    resourceList.splice(index, 1);
    props.setResourcesList(resourceList);
  };

  return (
    <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6">
      <div className="self-center" data-testid="resourcesList">
        <span>Resources: </span>
      </div>
      {props.resourcesList.map((resource, index) => {
        return (
          <React.Fragment key={index}>
            <div key={index} className="display: flex col-span-2  xl:col-span-4 xl:col-start-2 col-start-2">
              <div className="col-span-2  xl:col-span-3" data-testid="imageUpload_1">
                <span className="-ml-6 mt-4 absolute"> #{index + 1} </span>
                <div className="align-middle">
                  <UploadResource browseResource={addResourceItem} componentId={index} thisfile={props.resourcesList[index]} />
                </div>
              </div>
              <div className="display: flex items-center col-start-2  xl:col-start-2 mr-2" data-testid="imageUpload_1">
                <button
                  data-testid="eventSubmit"
                  className={'btn-main text-xs  '}
                  style={{ fontSize: '0.85rem' }}
                  onClick={() => {
                    handleResourceNameAction(resource, index);
                  }}
                >
                  Resource Name
                </button>
              </div>
              <div className="justify-self-start mt-4 xl:col-span-2">(Upload in Y format, max of 10MB)</div>
              <div className="display: flex items-center col-start-3  xl:col-start-3 ml-5" data-testid="imageUpload_1">
                <button
                  data-testid="eventSubmit"
                  className={'btn-main text-xs  '}
                  style={{ fontSize: '0.85rem' }}
                  onClick={() => {
                    removeResource(index);
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      })}
      {showForm && (
        <div className="display: flex xl:col-start-2 col-start-2 col-span-2  xl:col-span-3">
          <div className="col-span-2  xl:col-span-3" data-testid="imageUpload_1">
            <span className="-ml-6 mt-4 absolute"> #{props.resourcesList.length + 1} </span>
            <div className="align-middle">
              <UploadResource browseResource={addResourceItem} componentId={999} />
            </div>
          </div>
          <div className="justify-self-start mt-4 xl:col-span-2">(Upload in Y format, max of 10MB)</div>
          <div className="display: flex items-center col-start-2  xl:col-start-2 ml-5" data-testid="imageUpload_1">
            <button
              data-testid="eventSubmit"
              className={'btn-main text-xs  '}
              style={{ fontSize: '0.85rem' }}
              onClick={cancelResource}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <div className="col-start-2  xl:col-start-2" data-testid="imageUpload_1">
        <button
          data-testid="eventSubmit"
          className={'btn-main'}
          onClick={() => {
            addResource();
          }}
        >
          + Add a Resource
        </button>
      </div>
      {selectedResource && (
        <ModalDialog
          modalJSX={[
            <React.Fragment key={1}>
              <div className="flex mt-8 justify-center items-center" data-testid="changeResourceName">
                <div className="w-8/12 mr-2">
                  <input
                    data-testid="resourceName"
                    className="input-text"
                    value={resourceName}
                    onChange={e => {
                      if (e.target.value.length <= 40) {
                        setResourceName(e.target.value);
                      }
                    }}
                  />
                </div>
                <div>{resourceName.length}/40</div>
              </div>
            </React.Fragment>,
          ]}
          modalTitle={'Set resource name'}
          modalText={'According this name the resource will be displayed in the client app.'}
          closeShowModal={() => {
            setSelectedResource(null);
          }}
          confirmAction={() => {
            setNewResourceName();
          }}
          confirmBtnText={'Confirm'}
          showCancel={true}
        />
      )}
    </div>
  );
};
export default ResourcesForm;
