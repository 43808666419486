import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import PerkCatalogItem, { Perk } from '../../interfaces/perk';
import { Site } from '../../interfaces/site';
import { ROUTE_PATHS } from '../../utils/constants/route_paths';
import PlaytiPerksTable from './PlaytiPerksTable';
import ModalDialog from '../shared/Modal';
import tempData from "./tempData.json"
import MainImageDialog from '../products/MainImageDialog';
import PerkImage from '../../interfaces/perkImage';


const PlaytiPerksManagement: FunctionComponent = () => {
  const history = useHistory();
  const [allPlaytiPerks, setallPlaytiPerks] = useState<PerkCatalogItem[]>(tempData)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteItem, setDeleteItem] = useState({} as Perk)
  const [showImageModal, setShowImageModal] = useState(false)
  const [editImageItem, setEditImageItem] = useState({} as PerkCatalogItem)

  //open popover for adding new perk in the list of perks
  const addNewPerk = () => {
    history.push(ROUTE_PATHS.ADDPERK);
  };

  //view existing perk from the list of perks
  const onViewClick = (item: PerkCatalogItem) => {
    history.push(`${ROUTE_PATHS.VIEWPERK}/${item.id}`);
  };

  //edit existing perk from the list of perks
  const onEditClick = (item: PerkCatalogItem) => {
    history.push(`${ROUTE_PATHS.EDITPERK}/${item.id}`);
  };

  const onDeleteClick = () => {
    alert(`Deleted perk with ID: ${deleteItem.id}`)
  }

  const onAdditionalImagesClick = (item: PerkCatalogItem) => {
    setEditImageItem(item);
    setShowImageModal(true);
  };

  const onSaveImageList = async (hasChanges: boolean, updatedImageList: PerkImage[]) => {
    if (hasChanges === true) {
      const updateItem = editImageItem;
      editImageItem.perkImages = updatedImageList;
      const mainImage = updatedImageList.filter(c => c.mainImage === true);
      editImageItem.thumbnail = `https://d3nioszw1lcabk.cloudfront.net/${mainImage[0].imageKey}`;

      // await axiosApiInstance.put(`/perks/${updateItem?.id}`, JSON.stringify(updateItem)); To uncomment when API is enabled

      const updatePerkList = [...allPlaytiPerks];
      updatePerkList.map((currentPerk: PerkCatalogItem) => {
        if (currentPerk.id === editImageItem.id) {
          currentPerk.perkImages = updatedImageList;
        }
      });
      setallPlaytiPerks(updatePerkList);
    }
  };

  return (
    <div className="h-screen w-screen bg-white place-items-center px-10 py-12" data-testid="playtiPerksManagement">
      {/* add new perk section */}
      <div className="flex justify-start mb-3">
        <a data-testid="newPerk" onClick={addNewPerk} className={'btn-main'}>
          Add a New Perk
        </a>
      </div>
      <hr className="mb-6" />
      <PlaytiPerksTable onAdditionalImagesClick={onAdditionalImagesClick} allPlaytiPerks={allPlaytiPerks} setDeleteItem={setDeleteItem} setShowDeleteModal={setShowDeleteModal} onEditClick={onEditClick} onViewClick={onViewClick}/>
      {showDeleteModal && (
        <ModalDialog
          modalText={`Are you sure you wish to delete ${deleteItem.title} from the Perk’s Catalog?`}
          modalTitle={'DELETE PERK'}
          closeShowModal={() => {
            setShowDeleteModal(false);
          }}
          confirmAction={onDeleteClick}
          confirmBtnText={'Delete'}
          showCancel={true}
        />
      )}
      {showImageModal && (
        <MainImageDialog
          modalImageList={editImageItem.perkImages}
          modalTitle={`${editImageItem.title}'s Image`}
          closeShowModal={() => {
            setShowImageModal(false);
          }}
          confirmAction={onSaveImageList}
          confirmBtnText={'Delete'}
          editImages={() => onEditClick(editImageItem)}
          isPerk={true}
        />
      )}
    </div>
  );
};

export default PlaytiPerksManagement;
