import React, { FunctionComponent } from 'react';
// icons
import deleteIcon from '../../assets/images/delete-forever.png';
import viewIcon from '../../assets/images/viewFile.png';
import NotificationItem from '../../interfaces/notification';
import TableColProperty from '../shared/interfaces/TableColProperty';
import Table from '../shared/Table';
import Tooltip from '../shared/Tooltip';

//configuration array for the table body columns
const NC_COLUMN_PROPERTIES: TableColProperty[] = [
  { title: '_checkbox_', colMaxWidth: 'auto', colMinWidth: '40px', apiValue: '_checkbox_' },
  { title: 'Notification ID', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'id' },
  { title: 'Date & Time', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'createdAt' },
  { title: 'To office-location', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'zoneName' },
  { title: 'To studio', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'studioUnitName' },
  { title: 'To employee email', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'userEmail' },
  { title: 'Status', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'status' },
  { title: 'Title', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'title' },
  { title: 'Message', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'body' },
  { title: 'Action', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'action_items' },
];

export type NotificationsCenterTableProps = {
  allSelected: boolean;
  notificationList: NotificationItem[];
  onDeleteItemClick: (item: NotificationItem) => void;
  onSelectItem: (item: NotificationItem) => void;
  onSelectAllChange: () => void;
  onViewClick: (item: NotificationItem) => void;
};

const NotificationsCenterTable: FunctionComponent<NotificationsCenterTableProps> = props => {
  return (
    <div className="table-holder mb-6 px-3" data-testid="notificationsCenterTable">
      {/* table of notifications */}
      <Table
        columnProps={NC_COLUMN_PROPERTIES}
        allSelected={props.allSelected}
        onSelectAllChange={props.onSelectAllChange}
        tooltipTitle="No notifications to be selected"
      >
        {props.notificationList.map((item, index: number) => (
          <tr key={`pm_row_${index}`} data-testid="notification-row">
            {NC_COLUMN_PROPERTIES.map((property, ind) => {
              switch (property.apiValue) {
                case '_checkbox_':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <Tooltip title="Notifications Sent" position="right" show={true}>
                        <label className={`checkbox-container ${item.status === 'Sent' ? 'disabled' : ''}`}>
                          {' '}
                          <input
                            type="checkbox"
                            data-testid="selectone"
                            checked={item.checked}
                            onChange={() => {
                              props.onSelectItem(item);
                            }}
                            disabled={item.status === 'Sent'}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Tooltip>
                    </td>
                  );
                case 'zoneName':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.zoneName.length > 0 ? item.zoneName : '-'}
                    </td>
                  );
                case 'studioUnitName':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.studioUnitName.length > 0 ? item.studioUnitName : '-'}
                    </td>
                  );
                case 'userEmail':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.userEmail.length > 0 ? item.userEmail : '-'}
                    </td>
                  );
                case 'status':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.status === 'Pending' ? 'Pending...' : item.status}
                    </td>
                  );
                case 'createdAt':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.createdAt &&
                        new Intl.DateTimeFormat('default', { year: 'numeric', month: 'short', day: 'numeric' }).format(
                          new Date(item.createdAt)
                        )}
                    </td>
                  );
                case 'title':
                  return (
                    <td
                      key={`pm_col_${ind}`}
                      data-testid={`pm_col_${ind}`}
                      className=" overflow-ellipsis whitespace-nowrap overflow-hidden max-w-130"
                    >
                      {item.title.split('\n').length > 1 ? item.title.split('\n')[0] + ' ...' : item.title}
                    </td>
                  );
                case 'body':
                  return (
                    <td
                      key={`pm_col_${ind}`}
                      data-testid={`pm_col_${ind}`}
                      className=" overflow-ellipsis whitespace-nowrap overflow-hidden max-w-130"
                    >
                      {item.body.split('\n').length > 1 ? item.body.split('\n')[0] + ' ...' : item.body}
                    </td>
                  );
                case 'action_items':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <div className="flex align-middle w-16">
                        <a
                          className="cursor-pointer ml-2"
                          data-testid="delete_btn"
                          onClick={() => {
                            props.onDeleteItemClick(item);
                          }}
                        >
                          <img src={deleteIcon} />
                        </a>
                        <a
                          className="cursor-pointer w-12 mt-1 ml-2"
                          data-testid="view_btn"
                          onClick={() => {
                            props.onViewClick(item);
                          }}
                        >
                          <img src={viewIcon} />
                        </a>
                      </div>
                    </td>
                  );
                default:
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item[property.apiValue as keyof NotificationItem]}
                    </td>
                  );
              }
            })}
          </tr>
        ))}
      </Table>
    </div>
  );
};

export default NotificationsCenterTable;
