import React, { FunctionComponent, useState } from 'react';
import Image from '../../interfaces/Image';

interface Props {
  modalTitle: string;
  isPerk?: boolean
  modalImageList: Image[];
  confirmBtnText: string;
  closeShowModal: () => void;
  editImages: () => void;
  confirmAction: (hasChanges: boolean, updatedImageList: Image[]) => void;
}

const Modal: FunctionComponent<Props> = ({ modalTitle, modalImageList, closeShowModal, confirmAction, editImages, isPerk }) => {
  const [mainImageId, setMainImageId] = useState('0');

  const saveImageOrder = () => {
    const imageList = [] as Image[];
    let hasChanges = false;
    modalImageList.forEach((currImage: Image) => {
      if (currImage.id === mainImageId) {
        if (currImage.mainImage === false) {
          hasChanges = true;
        }
        currImage.mainImage = true;
      } else {
        currImage.mainImage = false;
      }
      imageList.push(currImage);
    });
    confirmAction(hasChanges, imageList);
    closeShowModal();
  };

  return (
    <>
      <div
        data-testid="modalImages"
        className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-auto h-5/6 my-4 mx-auto max-w-2xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-xl font-bold">{modalTitle}</h3>
              <button
                data-testid="close-button"
                className="p-1 z-50 ml-auto rounded-lg -mt-2 bg-transparent border text-black opacity-3 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => closeShowModal()}
              >
                <span className="bg-transparent -mt-1 text-black px-1 h-7 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-blueGray-500 text-lg leading-relaxed font-regular">
                Select the {isPerk ? `perks` : 'products'} Main Picture that will be shown on the {isPerk ? 'Perk Catalog' : 'Product Catalog'}, the other images will be shown in the
                Products page.
              </p>
            </div>
            <div className="w-4/6 ml-20">
              <div>
                {modalImageList.map((item: Image, index: number) => {
                  return (
                    <div className="grid table-holder gap-2 grid-cols-3 place-items-center mb-5" key={index}>
                      <div>
                        <input
                          type="radio"
                          checked={mainImageId !== '0' ? item.id === mainImageId : item.mainImage}
                          onChange={() => {
                            setMainImageId(item.id);
                          }}
                        />
                      </div>
                      <div className="-ml-32">Make main image</div>
                      <div className="-ml-20 w-4/5">
                        <img src={`https://d3nioszw1lcabk.cloudfront.net/${item.imageKey}`} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                data-testid="cancel-button"
                className="bg-emerald-500 text-gray-800 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-5 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => editImages()}
              >
                EDIT IMAGES
              </button>
              <button
                data-testid="confirm-button"
                className="bg-emerald-500 text-gray-800 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  saveImageOrder();
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default Modal;
