import axios, { AxiosInstance } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import useAuthStore from '../stores/authStore';
import useSettings from '../stores/settings';
import { msalInstance } from './msal-context';

export const useInterceptor = (gateway?: boolean): [AxiosInstance, number] => {
  const axiosApiInstance = axios.create({
    baseURL: gateway ? process.env.REACT_APP_GATEWAY_API_URL : process.env.REACT_APP_API_URL
  });
  //counter state for show/hide loader, when there are pending requests
  const [counter, setCounter] = useState(0);
  const inc = useCallback(() => setCounter(counter => counter + 1), [setCounter]); // add to counter
  const dec = useCallback(() => setCounter(counter => counter - 1), [setCounter]); // remove from counter
  const { msalToken, setMsalToken, setAuthenticated } = useAuthStore(); // load properties from auth store that will change on login logout
  const { setLoading } = useSettings();
  const interceptors = useMemo(
    () => ({
      request: (config: any) => (inc(), config),
      response: (response: any) => (dec(), response),
      error: (error: any) => (dec(), Promise.reject(error))
    }),
    [inc, dec]
  ); // create the interceptors

  useEffect(() => {
    // Request interceptor for API calls
    //Add bearer token on every request
    axiosApiInstance.interceptors.request.use(
      async config => {
        config.headers = {
          Authorization: `Bearer ${msalToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8'
        };
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );

    // Response interceptor for API calls
    axiosApiInstance.interceptors.response.use(
      response => {
        response.data = response.data.body;
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error);
        }

        if (error.response.status === 401 && !originalRequest._retry) {
          //set isAuthorized to false
          //delete acces token
          //logout from msal
          setAuthenticated(false);
          setMsalToken('');
          msalInstance.logout();
        }
        return Promise.reject(error);
      }
    );
  });

  useEffect(() => {
    // add request interceptors
    const reqInterceptor = axiosApiInstance.interceptors.request.use(interceptors.request, interceptors.error);
    // add response interceptors
    const resInterceptor = axiosApiInstance.interceptors.response.use(interceptors.response, interceptors.error);
    return () => {
      // remove all intercepts when done
      axiosApiInstance.interceptors.request.eject(reqInterceptor);
      axiosApiInstance.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);
  useEffect(() => {
    if (counter > 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [counter]);

  return [axiosApiInstance, counter];
};
