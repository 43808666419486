/**
 * Copyrights 2021 Playtika Ltd., all rights reserved to Playtika Ltd.
 * privacy+e17bb14d-edc1-4d26-930d-486fcc1ab8fe@playtika.com
 */

import * as msal from 'msal';
import { msalConfig } from './msal-config';

//msal instance
export const msalInstance = new msal.UserAgentApplication(msalConfig);

const reqParams: msal.AuthenticationParameters = { scopes: ['User.Read'] };
//login function that call msal login
export const login = (): void => {
    msalInstance.loginRedirect(reqParams);
};
//login function that call msal login
export const logout = (): void => {
    msalInstance.logout();
};
//getMsalToken - function that acquire token after the user have been logged in
export const getMsalToken: () => void | Promise<void | msal.AuthResponse> = () => {
    return msalInstance
        .acquireTokenSilent(reqParams)
        .catch(() => {
            return msalInstance.acquireTokenRedirect(reqParams);
        })
        .then((res) => {
            return res;
        });
};
