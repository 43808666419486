import React, { FunctionComponent } from 'react';
import DropDown from '../../shared/custom_components/DropDown';
import { CategoryBasic } from '../../../interfaces/category';

interface Props {
  isEditCategory: boolean;
  categoryId: string | undefined;
  categoryName: string;
  setCategoryName: (v: string) => void;
  active: boolean;
  setActive: (v: boolean) => void;
  isContentPage: boolean;
  setIsContentPage: (v: boolean) => void;
  hasChildren: boolean;
  parentId: string | undefined;
  availableParents: CategoryBasic[];
  setParentId: (v: string | undefined) => void;
}

const CategoryForm: FunctionComponent<Props> = props => {
  let parentCategoryName = '';
  let parentCategories: string[] = [];
  const removeCategoryParents = 'Remove all category parents';

  // when editing the category the user has the possibility to
  // change category parent (ancestor) or to set the category without any ancestors
  if (props.isEditCategory) {
    const parentCategory = props.availableParents.find(ele => ele.id === props.parentId);
    if (parentCategory) {
      parentCategoryName = parentCategory.name;
    }
    // the available options for parent categories
    // if the category has descendants they are all removed from the parents list
    // because it can not be set as a descendant to his own descendants
    // the filtration is done on the BE side
    parentCategories = props.availableParents.map(ele => ele.name);

    // option to set the category with no ancestors
    if (props.parentId && props.parentId !== 'undefined') {
      parentCategories.splice(0, 0, removeCategoryParents);
    }
  }

  const setParentCategory = (index: string) => {
    const parsedIndex = parseInt(index);
    if (parsedIndex === 0 && parentCategories[0] === removeCategoryParents) {
      // set parentId to null (option category to have no ancestors)
      props.setParentId(undefined);
    } else {
      if (parentCategories[0] === removeCategoryParents) {
        props.setParentId(props.availableParents[parsedIndex - 1].id);
      } else {
        props.setParentId(props.availableParents[parsedIndex].id);
      }
    }
  };

  return (
    <div className="ml-10 mr-10 w-6/6">
      <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="addEdiForm">
        <div className={(props.isEditCategory === true ? 'visible  ' : 'hidden ') + 'self-center mb-2'}>
          <span>Category ID:</span>
        </div>
        <div
          className={(props.isEditCategory === true ? 'visible ' : 'hidden ') + 'col-span-3  xl:col-span-5 mb-2'}
          data-testid="categoryId"
        >
          <span>{props.categoryId}</span>
        </div>

        {props.isEditCategory && props.availableParents && props.availableParents.length > 0 && (
          <React.Fragment>
            <div className="self-center">
              <span className="text-red text-xl -ml-4">* </span>
              <span>Set/change parent category:</span>
            </div>
            <div className="xl:col-span-3">
              <div className="dropdown w-full" data-testid="status">
                <DropDown
                  value={parentCategoryName}
                  onChange={(v: string) => {
                    setParentCategory(v);
                  }}
                  options={parentCategories}
                  returnIndex={true}
                />
              </div>
            </div>
            <div className="justify-self-start  mt-4  xl:col-span-2"></div>
          </React.Fragment>
        )}

        <div className="col-span-4 xl:col-span-6">
          <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="categoryInfo">
            <div className="self-center">
              <span className="text-red text-xl -ml-4">* </span>
              <span>Category Name:</span>
            </div>
            <div className="col-span-2  xl:col-span-3">
              <input
                data-testid="categoryName"
                className="input-text mb-3"
                value={props.categoryName}
                onChange={e => {
                  if (e.target.value.length <= 20) {
                    props.setCategoryName(e.target.value);
                  }
                }}
              />
            </div>
            <div className="justify-self-start mt-4 xl:col-span-2">{props.categoryName?.length}/20</div>

            <div className="self-center mb-4">Is &quot;Active&quot;?</div>
            <div className="justify-self-start mt-4 xl:col-span-1">
              <label className={`checkbox-container justify-start`}>
                <input
                  data-testid="chk_active"
                  className="mr-4 leading-tight"
                  type="checkbox"
                  checked={props.active}
                  onChange={() => {
                    props.setActive(!props.active);
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>

            <div className="justify-self-start mt-4"></div>
            <div className="justify-self-start mt-4 xl:col-span-3"></div>
            <div className="self-center mb-4">Is &quot;Content Page&quot;?</div>
            <div className="justify-self-start mt-4 xl:col-span-1">
              <label className={`checkbox-container justify-start`}>
                <input
                  data-testid="chk_active"
                  className="mr-4 leading-tight"
                  type="checkbox"
                  checked={props.isContentPage}
                  onChange={() => {
                    props.setIsContentPage(!props.isContentPage);
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CategoryForm;
