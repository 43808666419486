/**
 * Copyrights 2021 Playtika Ltd., all rights reserved to Playtika Ltd.
 * privacy+e17bb14d-edc1-4d26-930d-486fcc1ab8fe@playtika.com
 */

import create, { State } from 'zustand';

interface Settings extends State {
  sidebarOpen: boolean;
  logged: boolean;
  loading: boolean;
  spinner: boolean;
  toggleSidebar: () => void;
  setLoading: (value: boolean) => void;
  setLogged: (value: boolean) => void;
  setSideBar: (value: boolean) => void;
  setSpinner: (value: boolean) => void;
}

// zustand store that returns global setting for the application
const useSettings = create<Settings>(
  (set): Settings => ({
    sidebarOpen: false,
    logged: false,
    loading: true,
    spinner: false,
    toggleSidebar: () => set(state => ({ sidebarOpen: !state.sidebarOpen })),
    setLoading: (value: boolean) => set(() => ({ loading: value })),
    setLogged: (value: boolean) => set(() => ({ logged: value })),
    setSideBar: (value: boolean) => set(() => ({ sidebarOpen: value })),
    setSpinner: (value: boolean) => set(() => ({ spinner: value }))
  })
);

export default useSettings;
