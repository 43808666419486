import React, { FunctionComponent, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import User from '../../interfaces/user';
import UserBalance from '../../interfaces/user_balance_history';

import TableColProperty from '../shared/interfaces/TableColProperty';
import Table from '../shared/Table';

interface Props {
  user: User;
  closeShowModal: () => void;
}

const UBH_COLUMN_PROPERTIES: TableColProperty[] = [
  { title: 'Transaction ID', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'id' },
  { title: 'Transaction Type', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'reason' },
  { title: 'Transaction Value', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'changeValue' },
  { title: 'User Balance', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'balance' }
];
const UserBalanceModal: FunctionComponent<Props> = ({ user, closeShowModal }) => {
  const [axiosApiInstance] = useInterceptor();
  const [balanceHistoryData, setBalanceHistoryData] = useState([] as UserBalance[]);

  useEffect(() => {
    //get all user balance data
    axiosApiInstance.get(`/user/${user.id}/balance-history`).then(
      (res: AxiosResponse) => {
        setBalanceHistoryData(res.data as UserBalance[]);
      },
      () => {
        toast.error('No data available');
      }
    );
  }, []);
  return (
    <>
      <div
        data-testid="modalBalance"
        className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-4/6 h-5/6 my-4 mx-auto max-w-2xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-xl font-semibold">{user.firstName + ' ' + user.lastName}</h3>
              <button
                data-testid="close-button"
                className="p-1 z-50 ml-auto rounded-lg -mt-2 bg-transparent border text-black opacity-3 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => closeShowModal()}
              >
                <span className="bg-transparent -mt-1 text-black px-1 h-7 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-blueGray-500 text-lg font-semibold leading-relaxed">Employee email: {user.email}</p>
              <p className="my-4 text-blueGray-500 text-lg font-semibold leading-relaxed">
                Office: {user.site ? user.site.name : ''}
              </p>
              <p className="my-4 text-blueGray-500 text-lg font-semibold leading-relaxed">
                Current balance: {user.currentBalance}
              </p>
            </div>
            <div className="w-4/6 ml-20">
              <div className="table-holder mb-6">
                <Table columnProps={UBH_COLUMN_PROPERTIES}>
                  {balanceHistoryData.map((item, index: number) => (
                    <tr key={`pm_row_${index}`} data-testid="userBalance-row">
                      {UBH_COLUMN_PROPERTIES.map((property, ind) => {
                        switch (property.apiValue) {
                          case 'id':
                            return (
                              <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                                {item.id}
                              </td>
                            );
                          case 'userId':
                            return (
                              <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                                {item.userId}
                              </td>
                            );
                          case 'reason':
                            return (
                              <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                                {item.reason}
                              </td>
                            );
                          case 'changeValue':
                            return (
                              <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                                {item.changeValue}
                              </td>
                            );
                          case 'balance':
                            return (
                              <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                                {item.currentBalance}
                              </td>
                            );
                        }
                      })}
                    </tr>
                  ))}
                </Table>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                data-testid="cancel-button"
                className="bg-emerald-500 text-gray-800 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-5 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeShowModal()}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default UserBalanceModal;
