import React, { FunctionComponent } from 'react';
import MainNavigation from './MainNavigation';

const Header: FunctionComponent = () => {
  return (
    <div data-testid="header" className="bg-red w-full z-0">
      <div>
        <MainNavigation />
      </div>
    </div>
  );
};

export default Header;
