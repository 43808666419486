import React, { useEffect, useRef, useState } from 'react';

interface TooltipProps {
  title: string;
  position: string;
  show: boolean;
  children: any;
  showChildrenAsContent?: boolean;
  placeholderElement?: any;
}

const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const element = useRef<HTMLDivElement>(document.createElement('div'));
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  const handleClick = (event: MouseEvent) => {
    if (event.target instanceof Element) {
      if (element.current.contains(event.target)) {
        // inside click
        return;
      } else {
        setVisible(false);
      }
    }
  };
  return (
    <div
      data-testid="tooltip"
      ref={element}
      onClick={() => {
        if (props.show) {
          setVisible(!isVisible);
        }
      }}
    >
      <div data-testid="tooltip-placeholder">{!props?.placeholderElement ? props.children : props.placeholderElement}</div>
      {isVisible && (
        <div className="tooltip-right tooltip-right-checkbox" data-testid="tooltip-content">
          <span></span>
          {!props?.showChildrenAsContent ? props.title : props.children}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
