/**
 * Copyrights 2021 Playtika Ltd., all rights reserved to Playtika Ltd.
 * privacy+e17bb14d-edc1-4d26-930d-486fcc1ab8fe@playtika.com
 */

export const validateNumber = (inputValue: string) => {
  const re = /^-?[0-9]\d*\.?\d*\/?$/;
  if (inputValue === '' || re.test(inputValue)) {
    return true;
  } else {
    return false;
  }
};

export const validateArray = (inputValue: string) => {
  const re = /^-?[0-9]\d*\.?\d*\/?$/;
  const valueArray = inputValue.split('/');
  let validate = false;
  valueArray.forEach(item => {
    if (item === '' || re.test(item)) {
      validate = true;
    } else {
      return false;
    }
  });

  return validate;
};

export const validateEmail = (inputValue: string) => {
  const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  if (re.test(inputValue)) {
    return true;
  } else {
    return false;
  }
};
