import { AxiosInstance } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import { SystemConfiguration } from '../../interfaces/systemConfiguration';
import { validateEmail, validateNumber } from '../../utils/validations';

const SystemSettings: FunctionComponent = () => {
  const [maxAmountAllowedPerGift, setMaxAmountAllowedPerGift] = useState(0);
  const [maxYearlyAmountForGiftsPerUser, setMaxYearlyAmountForGiftsPerUser] = useState(0);
  const [sysNotificationEmail, setSysNotificationEmail] = useState('');
  const [pShopTeamContactEmail, setPShopTeamContactEmail] = useState('');
  const [birthdayGiftsAmount, setBirthdayGiftsAmount] = useState(0);

  // Validation error messages
  const [errSysNotification, setErrSysNotification] = useState(true);
  const [errPShopTeamContact, setErrorPShopContact] = useState(true);
  const [errValidateSave, setValidateSave] = useState(true);

  const [axiosApiInstance] = useInterceptor();

  const replaceAll = (string: string, search: string, replace: string) => {
    return string.split(search).join(replace);
  };

  useEffect(() => {
    getInitialConfig(axiosApiInstance);
  }, []);

  const getInitialConfig = (axiosApiInstance: AxiosInstance) => {
    axiosApiInstance.get(`/system-configuration`).then(res => {
      const dbConfig = res.data as SystemConfiguration;
      if (dbConfig) {
        if (dbConfig.maxAmountAllowedPerGift) {
          setMaxAmountAllowedPerGift(dbConfig.maxAmountAllowedPerGift);
        }
        if (dbConfig.maxYearlyAmountForGiftsPerUser) {
          setMaxYearlyAmountForGiftsPerUser(dbConfig.maxYearlyAmountForGiftsPerUser);
        }
        if (dbConfig.sysNotificationEmail) {
          setSysNotificationEmail(dbConfig.sysNotificationEmail.toString());
        }
        if (dbConfig.pShopTeamContactEmail) {
          setPShopTeamContactEmail(dbConfig.pShopTeamContactEmail.toString());
        }
        if (dbConfig.birthdayGiftsAmount) {
          setBirthdayGiftsAmount(dbConfig.birthdayGiftsAmount);
        }
      }
    });
  };

  const validateSave = () => {
    if (sysNotificationEmail === '' || pShopTeamContactEmail === '') {
      return false;
    } else {
      return true;
    }
  };

  const saveCurrConfig = async () => {
    const validSave = validateSave();
    setValidateSave(validSave);
    if (validSave) {
      const configData = {
        sysNotificationEmail,
        pShopTeamContactEmail,
        birthdayGiftsAmount,
        maxAmountAllowedPerGift,
        maxYearlyAmountForGiftsPerUser,
      };

      axiosApiInstance.post(`/system-configuration/`, configData).then(
        () => {
          toast.success('Data is successfully submitted');
        },
        () => {
          toast.error('Something went wrong');
        }
      );
    }
  };

  return (
    <div className="static" data-testid="systemSettings">
      <div className="h-screen w-screen bg-white place-items-center px-10 py-12" data-testid="submitDiv">
        <div className="text-gray-700" data-testid="configSettings">
          <span className="text-gray-700 flex">The following parameters are part of the PlaytiShop configurations:</span>
        </div>
        <div data-testid="general" className="mt-10">
          <span className="text-gray-700 font-bold">General:</span>
          <div>
            <div className="first-label-div">
              <span className="first-label">Maximum allowed amount of coins that can be sent from admin to user per gift:</span>
              <input
                data-testid="maxAmountAllowedPerGift"
                className="number-input mr-2 md:ml-3"
                value={maxAmountAllowedPerGift}
                onChange={e => {
                  if (validateNumber(e.target.value)) {
                    setMaxAmountAllowedPerGift(e.target.value ? parseInt(e.target.value) : 0);
                  }
                }}
              />
              <span className="text-gray-700 self-center">PlaytiCoins</span>
            </div>
            <div className="first-label-div">
              <span className="first-label">
                Maximum allowed amount of coins that user can receive yearly from gifts sent by admins:
              </span>
              <input
                data-testid="maxYearlyAmountForGiftsPerUser"
                className="number-input mr-2 md:ml-3"
                value={maxYearlyAmountForGiftsPerUser}
                onChange={e => {
                  if (validateNumber(e.target.value)) {
                    setMaxYearlyAmountForGiftsPerUser(e.target.value ? parseInt(e.target.value) : 0);
                  }
                }}
              />
              <span className="text-gray-700 self-center">PlaytiCoins</span>
            </div>
            <div className="first-label-div mb-10">
              <span className="first-label">Email for System Notifications</span>
              <div className="inline-block relative  w-4/12 md:w-3/6">
                <input
                  data-testid="sysNotificationEmail"
                  className="input-email md:w-52 md:ml-3 mt-2"
                  value={sysNotificationEmail}
                  onChange={e => setSysNotificationEmail(e.target.value)}
                  onBlur={e => {
                    setErrSysNotification(validateEmail(e.target.value));
                  }}
                />
                {!errSysNotification && (
                  <span data-testid="errorSysNotificationEmail" className="text-red text-sm  md:ml-3">
                    Please enter valid email!
                  </span>
                )}
                <div className="text-gray-700 pt-1 text-sm md:ml-3 absolute">e.g. Order delivery date has reached</div>
              </div>
            </div>
            <div className="first-label-div">
              <span className="first-label">Email for Playtikans to contact the PlaytiShop team</span>
              <div className="inline-block relative  w-4/12 md:w-3/6">
                <input
                  data-testid="pShopTeamContactEmail"
                  className="input-email md:w-52 md:ml-3 mt-2"
                  value={pShopTeamContactEmail}
                  onChange={e => setPShopTeamContactEmail(e.target.value)}
                  onBlur={e => {
                    setErrorPShopContact(validateEmail(e.target.value));
                  }}
                />
                {!errPShopTeamContact && (
                  <span data-testid="errorContactEmail" className="text-red text-sm md:ml-3">
                    Please enter valid email!
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div data-testid="giftConfig" className="mt-10">
          <span className="text-gray-700 font-bold">Gifts:</span>
          <div className="first-label-div">
            <span className="first-label">Birthday gift amount:</span>
            <input
              data-testid="birthdayGiftsAmount"
              className="number-input mr-2 md:ml-3"
              value={birthdayGiftsAmount}
              onChange={e => {
                if (validateNumber(e.target.value)) {
                  setBirthdayGiftsAmount(e.target.value ? parseInt(e.target.value) : 0);
                }
              }}
            />
            <span className="text-gray-700 self-center">PlaytiCoins</span>
          </div>
        </div>
        <div className="pb-10 pt-8">
          <div className="flex justify-center pb-2">
            {!errValidateSave && (
              <span data-testid="errorSubmit" className="text-red mx-5">
                Please fill in all fields!
              </span>
            )}
          </div>
          <div className="flex justify-center">
            <a
              href="#"
              data-testid="eventLogin"
              onClick={() => {
                saveCurrConfig();
              }}
              className={'btn-main'}
            >
              Submit
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemSettings;
