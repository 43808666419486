import React, { FunctionComponent, useState } from 'react';
import pendingGiftsIcon from '../../assets/svg/giftIcon.svg';
import historyIcon from '../../assets/svg/historyIcon.svg';
import { UserItem } from '../../interfaces/user';
import TableColProperty from '../shared/interfaces/TableColProperty';
import Table from '../shared/Table';
import deleteIcon from '../../assets/images/delete-forever.png';
import editIcon from '../../assets/images/edit.png';
import viewIcon from '../../assets/images/view.png';
import PerkCatalogItem from '../../interfaces/perk';


//configuration array for the table body columns
export const PPM_COLUMN_PROPERTIES: TableColProperty[] = [
  { title: 'ID', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'id' },
  { title: 'Title', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'title' },
  { title: 'Sub-title', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'subTitle' },
  { title: 'Description', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'description' },
  { title: 'Service Type', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'serviceType' },
  { title: 'Service Type Duration', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'serviceTypeDuration' },
  { title: 'Phone Number', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'phone' },
  { title: 'Contact Details', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'contactDetails' },
  { title: 'Segment', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'segment' },
  { title: 'Location', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'location'},
  { title: 'URL', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'url' },
  { title: 'Provider Image', colMaxWidth: 'auto', colMinWidth: '30px', apiValue: 'providerImage' },
  { title: 'Perk Images', colMaxWidth: 'auto', colMinWidth: '150px', apiValue: 'perkImages' },
  { title: 'Perk Status', colMaxWidth: 'auto', colMinWidth: '30px', apiValue: 'perkStatus' },
  { title: 'Perk Group', colMaxWidth: 'auto', colMinWidth: '30px', apiValue: 'perkGroup' },
  { title: 'Perk Recurrence Type', colMaxWidth: 'auto', colMinWidth: '30px', apiValue: 'perkRecurrenceType' },
  { title: 'Perk Type', colMaxWidth: 'auto', colMinWidth: '30px', apiValue: 'perkType' },
  { title: 'Coupon Code', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'couponCode' },
  { title: 'Date & Time', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'dateTime' },
  { title: 'End-date', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'endDate' },
  { title: 'Cost', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'cost' },
  { title: 'Discount', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'discount' },
  { title: 'Link to site', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'siteLink' },
  { title: 'Is New', colMaxWidth: 'auto', colMinWidth: '30px', apiValue: 'isNew' },
  { title: 'Must Have', colMaxWidth: 'auto', colMinWidth: '30px', apiValue: 'mustHaveLabel' },
  { title: 'Action', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'action' },
];

export type PlaytiPerksTableProps = {
   onEditClick: (item: PerkCatalogItem) => void;
   onViewClick: (item: PerkCatalogItem) => void;
   setShowDeleteModal: (b: boolean) => void;
   setDeleteItem: (item: PerkCatalogItem) => void;
   allPlaytiPerks: PerkCatalogItem[];
   onAdditionalImagesClick: (item: PerkCatalogItem) => void;
};

const PlaytiPerksTable: FunctionComponent <PlaytiPerksTableProps> = (props) => {
  return (
    <div className="table-holder mb-6" data-testid="playtiPerksTable">
      <Table
        columnProps={PPM_COLUMN_PROPERTIES}
      >
        {props.allPlaytiPerks.map((item: PerkCatalogItem, index: number) => (
          <tr key={`pm_row_${index}`} data-testid="playtiperks-row">
            {PPM_COLUMN_PROPERTIES.map((property, ind) => {
              switch (property.apiValue) {
                case 'isNew':
                  return (
                    <td key={`pp_col_${ind}`} data-testid={`pp_col_${ind}`}>
                      <label className="checkbox-container disabled">
                        {' '}
                        <input type="checkbox" checked={item.isNew} readOnly />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                case 'mustHaveLabel':
                  return (
                    <td key={`pp_col_${ind}`} data-testid={`pp_col_${ind}`}>
                      <label className="checkbox-container disabled">
                        {' '}
                        <input type="checkbox" checked={item.mustHaveLabel} readOnly />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                case 'perkImages':
                  return (
                    <td key={`pp_col_${ind}`} data-testid={`pp_col_${ind}`}>
                      {item.thumbnail && <img src={item.thumbnail} /> }
                      <h6 onClick={() => props.onAdditionalImagesClick(item)} className="cursor-pointer text-red hover:text-red_shadow">
                      Additional Images
                      </h6>
                    </td>
                  );
                case 'contactDetails':
                  return (
                    <td key={`pp_col_${ind}`} data-testid={`pp_col_${ind}`}>
                      {item.contactDetails?.name && (<p className="font-extrabold">Name:{item.contactDetails?.name}</p>)}
                      {item.contactDetails?.email && (<p className="font-extrabold">Email:{item.contactDetails?.email}</p>)}
                      {item.contactDetails?.address && (<p className="font-extrabold">Address:{item.contactDetails?.address}</p>)}
                      {item.contactDetails?.phone && (<p className="font-extrabold">Phone:{item.contactDetails?.phone}</p>)}
                    </td>
                  );
                case 'providerImage':
                  return (
                    <td key={`pp_col_${ind}`} data-testid={`pp_col_${ind}`}>
                      {item.providerImage}
                    </td>
                  );
                case 'dateTime':
                  return (
                    <td key={`pp_col_${ind}`} data-testid={`pp_col_${ind}`}>
                      {item.dateTime}
                    </td>
                  );
                case 'action':
                  return (
                    <td key={`pp_col_${ind}`} data-testid={`pp_col_${ind}`}>
                      <div className="flex align-middle">
                        <a
                          className="cursor-pointer"
                          data-testid="view_btn"
                          onClick={() => {
                            props.onViewClick(item);
                          }}
                        >
                          <img src={viewIcon} />
                        </a>
                        <a className="px-1 cursor-pointer"
                           data-testid="edit_btn"
                          onClick={() => props.onEditClick(item)}
                        >
                          <img src={editIcon} />
                        </a>
                        <a
                          className="cursor-pointer"
                          data-testid="delete_btn"
                          onClick={() => {
                            props.setShowDeleteModal(true);
                            props.setDeleteItem(item);
                          }}
                        >
                          <img src={deleteIcon} />
                        </a>
                      </div>
                    </td>
                  );
                default:
                  return (
                    <td key={`pp_col_${ind}`} data-testid={`pp_col_${ind}`}>
                      {item[property.apiValue as keyof PerkCatalogItem] === "" ? "N/A" : item[property.apiValue as keyof PerkCatalogItem]}
                    </td>
                  );
              }
            })}
          </tr>
        ))}
      </Table>
    </div>
  );
};

export default PlaytiPerksTable;
