import React from 'react';
import TableColProperty from './interfaces/TableColProperty';
import Tooltip from './Tooltip';

export type TableProps = {
  columnProps: TableColProperty[];
  allSelected?: boolean;
  onSelectAllChange?: () => void;
  disableSelectAll?: boolean;
  children: React.ReactNode;
  tooltipTitle?: string;
};
//draws table with defined styles
//header of the table is created according to the titles received in the props (title from columnProps foreach column)
//bopdy of the table is received as children prop
const Table: React.FC<TableProps> = (props: TableProps) => {
  return (
    <table className="playti-table table-auto" data-testid="table">
      <thead>
        <tr>
          {props.columnProps.map((colProp, index) => {
            if (colProp.title === '_checkbox_') {
              return (
                <th
                  style={{ maxWidth: colProp.colMaxWidth, minWidth: colProp.colMinWidth }}
                  key={`pm_table_th_${index}`}
                  data-testid="notificaion-table-th"
                >
                  <Tooltip title={props.tooltipTitle ? props.tooltipTitle : ''} position="right" show={!!props.disableSelectAll}>
                    <label className={`checkbox-container ${props.disableSelectAll ? 'disabled' : ''}`}>
                      {' '}
                      <input
                        type="checkbox"
                        data-testid="selectall"
                        checked={props.allSelected}
                        onChange={() => {
                          if (props.onSelectAllChange) {
                            props.onSelectAllChange();
                          }
                        }}
                        disabled={props.disableSelectAll}
                      />
                      <span className="checkmark bg-white"></span>
                    </label>
                  </Tooltip>
                </th>
              );
            } else {
              return (
                <th
                  style={{ maxWidth: colProp.colMaxWidth, minWidth: colProp.colMinWidth }}
                  key={`pm_table_th_${index}`}
                  data-testid="notificaion-table-th"
                >
                  {colProp.title}
                </th>
              );
            }
          })}
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  );
};

export default Table;
