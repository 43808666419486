import React, { FunctionComponent, useEffect, useState } from 'react';
import DropDown from '../shared/custom_components/DropDown';
import { useInterceptor } from '../../auth/interceptor';
import { toast } from 'react-toastify';
import Table from '../shared/Table';
import TableColProperty from '../shared/interfaces/TableColProperty';

export const LOCATION_COLUMN_PROPERTIES: TableColProperty[] = [
  { title: 'Name', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'name' },
  { title: 'Description', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'description' },
];

export const LOCATION_COLUMN_PROPERTIES_TIMEZONE: TableColProperty[] = [
  ...LOCATION_COLUMN_PROPERTIES,
  { title: 'Time Zone', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'timeZone' },
];

const AddLocation: FunctionComponent = () => {
  const formTypes = ['Zone', 'Site', 'Department', 'Studio / Unit'];
  const timeZones = [
    'GMT-11',
    'GMT-10',
    'GMT-9',
    'GMT-8',
    'GMT-7',
    'GMT-6',
    'GMT-5',
    'GMT-4',
    'GMT-3',
    'GMT-2',
    'GMT-1',
    'GMT',
    'GMT+1',
    'GMT+2',
    'GMT+3',
    'GMT+4',
    'GMT+5',
    'GMT+6',
    'GMT+7',
    'GMT+8',
    'GMT+9',
    'GMT+10',
    'GMT+11',
    'GMT+12',
  ];
  const [activeFormType, setActiveFormType] = useState('Zone');
  const [timeZone, setTimeZone] = useState('GMT');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [axiosApiInstance] = useInterceptor();
  const [gatewayAxiosApiInstance] = useInterceptor(true);
  const [zones, setZones] = useState([]);
  const [sites, setSites] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [studiosUnits, setStudiosUnits] = useState([]);

  useEffect(() => {
    getLocations('Zone');
  }, []);

  const getLocations = async (location: string) => {
    switch (location) {
      case 'Zone':
        try {
          const res = await gatewayAxiosApiInstance.get(`/settings/available-zones`);
          setZones(res.data);
        } catch (err) {
          toast.error('Something went wrong');
        }
        break;
      case 'Site':
        try {
          const res = await gatewayAxiosApiInstance.get(`/settings/sites`);
          setSites(res.data);
        } catch (err) {
          toast.error('Something went wrong');
        }
        break;
      case 'Department':
        try {
          const res = await gatewayAxiosApiInstance.get(`/departments`);
          setDepartments(res.data);
        } catch (err) {
          toast.error('Something went wrong');
        }
        break;
      case 'Studio / Unit':
        try {
          const res = await axiosApiInstance.get(`/settings/studios-units`);
          setStudiosUnits(res.data);
        } catch (err) {
          toast.error('Something went wrong');
        }
        break;
      default:
        toast.error('Location type has not been selected');
        break;
    }
  };

  const isFormValid = () => (activeFormType === 'Zone' ? !!name && !!description && !!timeZone : !!name && !!description);

  const resetInputs = () => {
    setErrorMsg('');
    setName('');
    setDescription('');
    setTimeZone('GMT');
  };

  const saveLocation = async () => {
    if (!isFormValid()) {
      setErrorMsg('Please fill in all required fields!');
      return;
    }

    const location = {
      name,
      description,
    };

    switch (activeFormType) {
      case 'Zone':
        try {
          await axiosApiInstance.post(`/settings/available-zones`, JSON.stringify({ ...location, timeZone }));
          toast.success('Zone saved successfully');
        } catch (err) {
          toast.error('Something went wrong');
        }
        break;
      case 'Site':
        try {
          await gatewayAxiosApiInstance.post(`/settings/sites`, JSON.stringify(location));
          toast.success('Site saved successfully');
        } catch (err) {
          toast.error('Something went wrong');
        }
        break;
      case 'Department':
        try {
          await gatewayAxiosApiInstance.post(`/departments`, JSON.stringify(location));
          toast.success('Department saved successfully');
        } catch (err) {
          toast.error('Something went wrong');
        }
        break;
      case 'Studio / Unit':
        try {
          await axiosApiInstance.post(`/settings/studios-units`, JSON.stringify(location));
          toast.success('Studio / Unit saved successfully');
        } catch (err) {
          toast.error('Something went wrong');
        }
        break;
      default:
        toast.error('Location type has not been selected');
        break;
    }
    getLocations(activeFormType);
    resetInputs();
  };

  const getLocationItems = (locations: { name: string; description: string; timeZone: string }[]) =>
    locations.map((item: { name: string; description: string; timeZone: string }, index) => (
      <tr key={`location_row_${index}`} data-testid="location-row">
        <td key={`location_col_${index}-name`}>{item.name}</td>
        <td key={`location_col_${index}-desc`}>{item.description}</td>
        {activeFormType === 'Zone' ? <td key={`location_col_${index}-tmz`}>{item.timeZone}</td> : ''}
      </tr>
    ));

  return (
    <div>
      <div className="xl:col-span-2 ml-10 mr-10 mt-5">
        <span className="mr-6 mb-3">Choose a location type to be added:</span>
        <div className="dropdown" data-testid="status">
          <DropDown
            value={activeFormType}
            onChange={(v: string) => {
              resetInputs();
              setActiveFormType(v);
              getLocations(v);
            }}
            options={formTypes}
          />
        </div>
      </div>
      <div className="mt-5">
        <span className="ml-10 mr-10 font-bold text-gray-700">Add {activeFormType}</span>
      </div>
      <div className="ml-10 mr-10 w-6/6" data-testid="addLocationForm">
        <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="locationName">
          <div className="self-center">
            <span className="text-red text-xl -ml-4">* </span>
            <span>Name:</span>
          </div>
          <div className="col-span-2  xl:col-span-3">
            <input
              value={name}
              maxLength={100}
              data-testid="locationNameInput"
              className="input-text mb-3"
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="justify-self-start mt-4 xl:col-span-2">{name.length}/100</div>
        </div>
        <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="locationDescription">
          <div className="self-center">
            <span className="text-red text-xl -ml-4">* </span>
            <span>Description:</span>
          </div>
          <div className="col-span-2 xl:col-span-3">
            <input
              value={description}
              maxLength={100}
              data-testid="locationDescriptionInput"
              className="input-text mb-3"
              onChange={e => setDescription(e.target.value)}
            />
          </div>
          <div className="justify-self-start mt-4 xl:col-span-2">{description.length}/100</div>
        </div>
        {activeFormType === 'Zone' ? (
          <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="locationTimezone">
            <div className="self-center">
              <span className="text-red text-xl -ml-4">* </span>
              <span>Timezone:</span>
            </div>
            <div className="col-span-2 xl:col-span-3">
              <div className="dropdown" data-testid="status">
                <DropDown value={timeZone} onChange={(v: string) => setTimeZone(v)} options={timeZones} />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {errorMsg.length > 0 && (
          <div className="flex justify-center mt-10 -mb-8 font-semibold">
            <span data-testid="errorSubmit" className="text-red mx-5">
              {errorMsg}
            </span>
          </div>
        )}
        <div className="h-20">
          <div className="flex justify-center mt-10">
            <button data-testid="eventSubmit" className={'btn-main'} onClick={saveLocation}>
              Submit
            </button>
          </div>
        </div>
        <div className="table-holder mb-6" data-testid="playticoinsTable">
          <Table
            columnProps={activeFormType === 'Zone' ? LOCATION_COLUMN_PROPERTIES_TIMEZONE : LOCATION_COLUMN_PROPERTIES}
            tooltipTitle="No users to be selected"
          >
            {activeFormType === 'Zone'
              ? getLocationItems(zones)
              : activeFormType === 'Department'
              ? getLocationItems(departments)
              : activeFormType === 'Site'
              ? getLocationItems(sites)
              : activeFormType === 'Studio / Unit'
              ? getLocationItems(studiosUnits)
              : ''}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AddLocation;
