import React, { FunctionComponent, useState, useEffect } from 'react';
import deleteIcon from '../../assets/images/delete-forever.png';
import editIcon from '../../assets/images/edit.png';
import viewIcon from '../../assets/images/view.png';
import addIcon from '../../assets/images/add.png';
import ProductCatalogItem from '../../interfaces/product';
import TableColProperty from '../shared/interfaces/TableColProperty';
import Table from '../shared/Table';
import { Site } from '../../interfaces/site';
import productSettings from '../../stores/productSettings';

export type ProductManagementTableProps = {
  sites: Site[];
  shownProducts: ProductCatalogItem[];
  onAdditionalImagesClick: (item: ProductCatalogItem) => void;
  setShowDeteleteModal: (b: boolean) => void;
  setDeleteItem: (item: ProductCatalogItem) => void;
  onViewClick: (item: ProductCatalogItem) => void;
  onEditClick: (item: ProductCatalogItem) => void;
  onDuplicateClick: (item: ProductCatalogItem) => void;
};

const ProductManagementTable: FunctionComponent<ProductManagementTableProps> = props => {
    //configuration array for the table body columns

    const { colPropertyList , setcolPropertyList } = productSettings();
    const [pmColumnProperties, setpmColumnProperties] = useState<TableColProperty[]>(colPropertyList)
    const [changeHandler, setChangeHandler] = useState(false)
    const [showFilter, setShowFilter] = useState(false)

    const colPropertiesHandler = (apiValue: string) => {
        setcolPropertyList (apiValue)
        setChangeHandler(!changeHandler)
    }

    useEffect(() => {
        setpmColumnProperties(colPropertyList.filter(col => col.isVisible === true))
    }, [changeHandler])

   return (
    <div className="table-holder" data-testid="productManagementTable">
      <h1 className="text-center text-sm btn-main mb-2 rounded-md w-60"
          onClick={() => setShowFilter(!showFilter)}>{showFilter ? 'Hide Filter Options' : 'Show Filter Options'} </h1>
      {showFilter ? (
          <div className="overflow-auto mb-3 bg-grey rounded-md h-60 w-60">
            {
              colPropertyList.map((column, index) => (
                column.title !== "Action" ?
                  <div className="flex justify-between p-2" style={{borderTop: '1px solid #d4d5d6'}} key={index}>
                    <label className="form-check-label inline-block text-gray-800 text-sm"
                           htmlFor={`flexCheckChecked-${column.title}`}>
                      {column.title}
                    </label>
                    <input
                      className="form-check-input ml-2 h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600  cursor-pointer"
                      type="checkbox" id={`flexCheckChecked-${column.title}`}
                      onChange={() => colPropertiesHandler(column.apiValue)} checked={column.isVisible}/>
                  </div>
                  : null
              ))
            }
          </div>)
        : null}
      {/* list of products in table section */}
      <Table columnProps={pmColumnProperties}>
        {props.shownProducts.map((item: ProductCatalogItem, index: number) => (
          <tr key={`pm_row_${index}`} data-testid="product_tr">
            {pmColumnProperties.map((property, ind) => {
              let displayText = 'N/A';
              switch (property.apiValue) {
                case 'propertyName1':
                case 'propertyName2':
                case 'propertyName3':
                case 'propertyName4':
                case 'propertyName5':
                  const numKey = parseInt(property.apiValue.substring(property.apiValue.length - 1, property.apiValue.length));
                  if (item.properties) {
                    const key_arr: string[] = Object.keys(item.properties);
                    if (key_arr[numKey - 1]) {
                      displayText = key_arr[numKey - 1];
                    }
                  }
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {displayText}
                    </td>
                  );
                case 'propertyValue1':
                case 'propertyValue2':
                case 'propertyValue3':
                case 'propertyValue4':
                case 'propertyValue5':
                  const numVal = parseInt(property.apiValue.substring(property.apiValue.length - 1, property.apiValue.length));
                  if (item.properties) {
                    const val_arr: string[] = Object.values(item.properties);
                    if (val_arr[numVal - 1]) {
                      displayText = val_arr[numVal - 1];
                    }
                  }
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {displayText}
                    </td>
                  );
                case 'thumbnail':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {item.thumbnail && <img src={item.thumbnail} />}
                      <div>
                        <a
                          className="cursor-pointer text-red hover:text-red_shadow"
                          data-testid="additional-images"
                          onClick={() => props.onAdditionalImagesClick(item)}
                        >
                          {' '}
                          Additional Images
                        </a>
                      </div>
                    </td>
                  );
                case 'availableInSites':
                  if (item.availableInSites.length === props.sites.length - 1) {
                    return (
                      <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                        <div className="mb-3" key={`z_all`}>
                          All
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                        {item.availableInSites.map((z, i) => (
                          <div className="mb-3" key={`z_${i}`}>
                            {z.name}
                          </div>
                        ))}
                      </td>
                    );
                  }
                case 'newProduct':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <label className="checkbox-container disabled">
                        {' '}
                        <input type="checkbox" checked={item.newProduct} readOnly data-testid={`new_product_checkbox`} />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                case 'mustHaveLabel':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <label className="checkbox-container disabled">
                        {' '}
                        <input type="checkbox" checked={item.mustHaveLabel} readOnly data-testid={`must_have_checkbox`} />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  );
                case 'action_items':
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      <div className="flex align-middle">
                        <a
                          className="cursor-pointer"
                          data-testid="view_btn"
                          onClick={() => {
                            props.onViewClick(item);
                          }}
                        >
                          <img src={viewIcon} />
                        </a>
                        <a className="px-1 cursor-pointer" data-testid="edit_btn" onClick={() => props.onEditClick(item)}>
                          <img src={editIcon} />
                        </a>
                        <a
                          className="cursor-pointer"
                          data-testid="delete_btn"
                          onClick={() => {
                            props.setShowDeteleteModal(true);
                            props.setDeleteItem(item);
                          }}
                        >
                          <img src={deleteIcon} />
                        </a>
                        <a
                            className="cursor-pointer ml-1"
                            data-testid="duplicate_button"
                            onClick={() => {
                              props.onDuplicateClick(item)
                            }}
                        >
                          <img src={addIcon} />
                        </a>
                      </div>
                    </td>
                  );
                default:
                  return (
                    <td key={`pm_col_${ind}`} data-testid={`pm_col_${ind}`}>
                      {
                        [property.apiValue as keyof ProductCatalogItem].toString() === "description"
                          ? (<div dangerouslySetInnerHTML={{__html: item.description}}/>)
                          : [property.apiValue as keyof ProductCatalogItem].toString() === "price" ? item.price.toFixed(2)
                            : [property.apiValue as keyof ProductCatalogItem].toString() === "published" ? JSON.stringify(item[property.apiValue as keyof ProductCatalogItem])
                              : [property.apiValue as keyof ProductCatalogItem].toString() === "priceOnSale" ? item.priceOnSale.toFixed(2)
                                : (item[property.apiValue as keyof ProductCatalogItem])
                      }
                    </td>
                  );
              }
            })}
          </tr>
        ))}
      </Table>
    </div>
  );
};

export default ProductManagementTable;
