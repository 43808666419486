/**
 * Copyrights 2021 Playtika Ltd., all rights reserved to Playtika Ltd.
 * privacy+e17bb14d-edc1-4d26-930d-486fcc1ab8fe@playtika.com
 */

import create, { State } from 'zustand';

interface AuthObj extends State {
  isAuthenticated: boolean;
  msalToken: string | undefined;
  setAuthenticated: (_isAuthenticated: boolean) => void;
  setMsalToken: (_msalToken: string) => void;
  setMsalAccessToken: (_msalAccessToken: string) => void;
}

//zustand store that returns authentication properties for the application
const useAuthStore = create<AuthObj>(
  (set): AuthObj => ({
    msalToken: undefined,
    setMsalToken: (_msalToken: string) => set(() => ({ msalToken: _msalToken })),
    msalAccessToken: undefined,
    setMsalAccessToken: (_msalAccessToken: string) => set(() => ({ msalAccessToken: _msalAccessToken })),
    isAuthenticated: false,
    setAuthenticated: (_isAuthenticated: boolean) => set(() => ({ isAuthenticated: _isAuthenticated })),
  })
);

export default useAuthStore;
