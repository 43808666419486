import React, { FunctionComponent, useRef } from 'react';
import Image from '../../../interfaces/Image';
import { Resources } from '../../../interfaces/category';
import { PromptState } from 'msal/lib-commonjs/utils/Constants';
import deleteIcon from '../../../assets/images/icon_decline.png';

interface Props {
  componentId: number;
  thisfile?: Image | Resources;
  fileName: string;
  handleChange: (e: any) => void;
  customStyle?: boolean;
  removeImageItem?: (removeImage: any) => void;
  setfileName?: (fileName: string) => void;
}

const UploadComponent: FunctionComponent<Props> = ({ componentId, thisfile, fileName, handleChange, customStyle, removeImageItem, setfileName }) => {
  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <div data-testid="uploadComponent" className={customStyle ? 'flex justify-center items-center' : ''}>
      <input
        data-testid="file-name-input"
        className="w-3/5 mr-4 input-text mb-2 mt-2"
        value={thisfile ? thisfile.name : fileName}
        readOnly
      />
      <button
        data-testid={`btnBrowse_${componentId}`}
        className="btn-upload"
        onClick={() => {
          fileInput?.current?.click();
        }}
      >
        Browse
      </button>
      {
        (removeImageItem && thisfile?.name) &&  <button
          data-testid={`btnRemove_${componentId}`}
          className="btn-upload ml-2"
          style={{height: '30px', width: '45px'}}
          onClick={() => {
            removeImageItem?.(thisfile);
            setfileName?.("")
          }}
        >
          <img src={deleteIcon}/>
        </button>
      }
      <input
        type="file"
        data-testid={`fileUpload_${componentId}`}
        id="imageBrowse_1"
        name="imageBrowse_1"
        accept="image/*"
        className="hidden"
        ref={fileInput}
        onChange={handleChange}
      />
    </div>
  );
};

export default UploadComponent;
