import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

interface Props {
  value: string;
  options: string[];
  placeholder?: string;
  disabled?: boolean;
  onChange: (selectedValue: string) => void;
  returnIndex?: boolean;
}

const Dropdown: FunctionComponent<Props> = ({
  value,
  options,
  placeholder = 'Select',
  onChange,
  disabled = false,
  returnIndex = false,
}) => {
  const node = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const handleChange = (selectedValue: string, e: any) => {
    e.preventDefault();
    onChange(selectedValue);
    setOpen(false);
  };

  const handleChangeI = (selectedValue: string, e: any, index: number) => {
    e.preventDefault();
    onChange(`${index}`);
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div data-testid="dropDown" ref={node} className={`dropdown relative w-full ${disabled ? ' disabled' : ''}`}>
      <button
        data-testid="dropDownOpen"
        className="bg-gray-100 w-full  text-gray-500 font-semibold py-2 px-4 rounded flex items-center z-10 justify-between flex-row flex-wrap"
        onClick={() => {
          if (!disabled) {
            setOpen(!open);
          }
        }}
      >
        <div className="mr-2 drop-down-placeholder" data-testid="placeholder">
          {value || placeholder}
        </div>
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{' '}
        </svg>
      </button>
      <ul
        className={open === true ? 'overflow-auto h-36 absolute block w-full z-50 ' : 'absolute hidden ' + '  text-gray-500 pt-1'}
      >
        {!returnIndex
          ? options.map((opt, index) => (
              <li
                key={index}
                data-testid={`item-click-${index}`}
                onClick={e => {
                  handleChange(opt, e);
                }}
                value={opt}
              >
                <a className="rounded-t bg-gray-200 py-2 px-4 block whitespace-no-wrap z-50" href="#">
                  {opt}
                </a>
              </li>
            ))
          : options.map((opt, index) => (
              <li
                key={index}
                data-testid={`item-click-${index}`}
                onClick={e => {
                  handleChangeI(opt, e, index);
                }}
                value={opt}
              >
                <a className="rounded-t bg-gray-200 py-2 px-4 block whitespace-no-wrap z-50" href="#">
                  {opt}
                </a>
              </li>
            ))}
      </ul>
    </div>
  );
};

export default Dropdown;
