import axios, { AxiosResponse } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import { CategoryType, Resources } from '../../interfaces/category';
import { ROUTE_PATHS } from '../../utils/constants/route_paths';
import CategoryForm from './AddEditCategory/CategoryForm';
import PageContentForm from './AddEditCategory/PageContentForm';
interface Props {
  isEditCategory: boolean;
  isSubCategory?: boolean;
}
interface Params {
  categoryId: string | undefined;
  parentIdParams: string | undefined;
}

const AddEditCategory: FunctionComponent<Props> = ({ isEditCategory, isSubCategory }) => {
  const history = useHistory();
  const [id, setId] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [active, setActive] = useState(true);
  const [isContentPage, setIsContentPage] = useState(false);
  const [conPageTitle, setConPageTitle] = useState('');
  const [conPageIntroText, setConPageIntroText] = useState('');
  const [conPageContent, setConPageContent] = useState('');
  const [resourcesList, setResourcesList] = useState([] as Resources[]);
  const { categoryId } = useParams<Params>();
  const { parentIdParams } = useParams<Params>();
  const [parentId, setParentId] = useState(parentIdParams);
  const [hasChildren, setHasChildren] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [axiosApiInstance] = useInterceptor(true);
  const [editCategory, setEditCategory] = useState({ name: '', active: true } as CategoryType);
  const [availableParents, setAvailableParents] = useState([]);

  // if the type of action is editing a category fetch the category data
  useEffect(() => {
    if (isEditCategory) {
      axiosApiInstance.get(`/cms/category/update/${categoryId}`).then(
        res => {
          if (res.data && res.data?.category?.children.length > 0) {
            setHasChildren(true);
          }
          setEditCategory(res.data.category);
          setAvailableParents(res.data.availableParentCategories);
        },
        () => {
          toast.error('category can not be loaded');
        }
      );
    }
  }, []);

  // if the type of action is editing a category fulfil the form with the fetched data
  useEffect(() => {
    if (isEditCategory && editCategory) {
      setId(editCategory.id);
      setCategoryName(editCategory.name);
      setActive(editCategory.active);
      setIsContentPage(!!editCategory?.pageContent);
      if (!!editCategory?.pageContent && Object.keys(editCategory?.pageContent).length > 0) {
        setConPageTitle(editCategory.pageContent.title);
        setConPageIntroText(editCategory.pageContent.introText);
        setConPageContent(editCategory.pageContent.content);
        if (editCategory.pageContent.resources && editCategory.pageContent.resources.length > 0) {
          const setResourceList = [] as Resources[];
          editCategory.pageContent.resources.forEach((resource: Resources, index: number) => {
            resource.componentId = index;
            setResourceList.push(resource);
          });
          setResourcesList(setResourceList);
        }
      }
    }
  }, [editCategory]);

  const saveCategory = async () => {
    let errorMsg = '';
    if (categoryName === '') {
      errorMsg = 'Please fill in all required fields!';
      setErrorMsg(errorMsg);
      return;
    }

    // show error if some required field is not fulfilled
    if (isContentPage && (conPageTitle === '' || conPageIntroText === '' || conPageContent === '')) {
      errorMsg = 'Please fill in all required fields!';
      setErrorMsg(errorMsg);
      return;
    }

    // construct the object
    let categoryObject: CategoryType = {
      id,
      name: categoryName,
      active,
      parentId: parentId !== 'undefined' ? parentId : null,
      pageContent: null,
      children: [],
    };

    // if the category has content (is content page) add the additional data to the object
    let contentPage;
    if (isContentPage) {
      contentPage = {
        title: conPageTitle,
        introText: conPageIntroText,
        content: conPageContent,
        resources: resourcesList,
      };
      categoryObject.pageContent = contentPage;
    }

    // add the resources to the object (only the new resources - does without id property)
    if (resourcesList.filter(c => !c.id).length > 0) {
      const countResourceUpload = resourcesList.filter(c => !c.id).length;
      let resourcesUploaded = 0;
      resourcesList.forEach((currentResource: Resources) => {
        if (!currentResource.id) {
          axios
            .put(currentResource.resourceUrl, currentResource.fileObject, {
              headers: {
                'Content-Type': currentResource.fileObject ? currentResource.fileObject.type : '',
              },
            })
            .then(
              (res: AxiosResponse) => {
                if (res.status === 200) {
                  resourcesUploaded += 1;
                  if (countResourceUpload === resourcesUploaded) {
                    toast.success('Category saved successfully');
                    history.push(ROUTE_PATHS.CMS);
                  } else {
                    return;
                  }
                }
              },
              () => {
                toast.error('Something went wrong');
              }
            );
        }
      });
    }

    // route and http request for updating an existing category
    if (isEditCategory === true) {
      try {
        await axiosApiInstance.put(`/cms/category/${editCategory?.id}`, JSON.stringify(categoryObject));
      } catch (err) {
        toast.error('Something went wrong');
      }
      // route and http request for creating a brand new category
    } else {
      try {
        await axiosApiInstance.post(`/cms/category/`, JSON.stringify(categoryObject));
      } catch (err) {
        toast.error('Something went wrong');
      }
    }
    history.push(ROUTE_PATHS.CMS);
  };

  const cancelAction = () => {
    history.push(ROUTE_PATHS.CMS);
  };

  return (
    <div data-testid="addEditCategory">
      <div className="mt-5">
        <span className="ml-10 mr-10 font-bold text-gray-700">{isEditCategory ? 'Edit category' : 'Add NEW category'}</span>
      </div>
      <div className="mb-10 ml-10 mr-10 border-t-2 border-gray-400"></div>
      <CategoryForm
        isEditCategory={isEditCategory}
        categoryId={categoryId}
        categoryName={categoryName}
        setCategoryName={setCategoryName}
        active={active}
        setActive={setActive}
        isContentPage={isContentPage}
        setIsContentPage={setIsContentPage}
        hasChildren={hasChildren}
        parentId={parentId}
        setParentId={setParentId}
        availableParents={availableParents}
      />
      {isContentPage && (
        <PageContentForm
          isEditCategory={isEditCategory}
          conPageTitle={conPageTitle}
          setConPageTitle={setConPageTitle}
          conPageIntroText={conPageIntroText}
          setConPageIntroText={setConPageIntroText}
          conPageContent={conPageContent}
          setConPageContent={setConPageContent}
          resourcesList={resourcesList}
          setResourcesList={setResourcesList}
        />
      )}
      {errorMsg.length > 0 && (
        <div className="flex justify-center mt-10 -mb-8 font-semibold">
          <span data-testid="errorSubmit" className="text-red mx-5">
            {errorMsg}
          </span>
        </div>
      )}
      <div className="h-20">
        <div className="flex justify-center mt-10 gap-x-2">
          <button
            data-testid="eventSubmit"
            className={'btn-main'}
            onClick={() => {
              saveCategory();
            }}
          >
            Submit
          </button>
          <button
            data-testid="eventSubmit"
            className={'btn-main'}
            onClick={() => {
              cancelAction();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddEditCategory;
