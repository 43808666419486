import React, { FunctionComponent, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import spinnerGif from './assets/images/spinner.gif';
import { useInterceptor } from './auth/interceptor';
import { getMsalToken, login, msalInstance } from './auth/msal-context';
// css
import './index.css';
import User from './interfaces/user';
// components
import Header from './modules/header/Header';
import Loading from './modules/shared/LoadingScreen';
import Routes from './modules/shared/Routes';
// store
import useAuthStore from './stores/authStore';
import useSettings from './stores/settings';
import userSettings from './stores/userSettings';

const App: FunctionComponent = () => {
  const { sidebarOpen, loading, setLoading, setLogged, spinner } = useSettings();
  const { msalToken, msalAccessToken, isAuthenticated, setAuthenticated, setMsalToken, setMsalAccessToken } = useAuthStore();
  const [initialLoading, setInitialLoading] = useState(true);
  const [axiosApiInstance] = useInterceptor();
  const { user, setUser } = userSettings();

  useEffect(() => {
    if (msalInstance) {
      //on mount first check if the user is logged in with msal
      if (msalInstance.getAccount()) {
        //if the user is logged in aquire token
        setLogged(true);
        getToken();
      } else {
        //if the user is not logged in set initialLoading to false so the loginScreen could show
        setInitialLoading(false);
        login();
      }
    }
  }, []);
  useEffect(() => {
    if (msalToken) {
      const configData = {
        accessToken: msalAccessToken,
      };
      //when we receive the token from msal create that user on our API
      axiosApiInstance.post(`${process.env.REACT_APP_API_URL}/user/signup-back-office`, JSON.stringify(configData)).then(
        res => {
          //if user is successfuly signed in set isAuthenticated to true and load the application
          setLoading(false);
          setUser(res.data as User);
          setAuthenticated(true);
        },
        () => {
          //is the user is not successfuly signed in login again
          login();
        }
      );
    }
  }, [msalToken]);
  useEffect(() => {
    //when toke expire 'isAuthanticated' will be set to false
    //when 'isAuthanticated' is set to false, and it is not on application load (we already have logged in user), login again
    if (!isAuthenticated) {
      if (user) {
        login();
      }
    } else {
      setInitialLoading(false);
    }
  }, [isAuthenticated]);

  const getToken = async () => {
    // call getMsalToken function, that will aquire msal token
    const token = await getMsalToken();
    if (token) {
      if (token.accessToken) {
        //set msal access token in store
        setMsalAccessToken(token.accessToken);
      }
      if (token.idToken) {
        //set msal token in store
        setMsalToken(token.idToken.rawIdToken);
      } else {
        msalInstance.logout();
      }
    } else {
      if (isAuthenticated) {
        setAuthenticated(false);
        setMsalToken('');
        msalInstance.logout();
      }
    }
  };
  return (
    <div data-testid="app">
      {spinner && (
        <div className="absolute h-screen pointer-events-none w-screen z-10 flex items-center justify-center bg-opacity-30 bg-black">
          <div className=" flex">
            <img width="63px" height="63px" src={spinnerGif} />
          </div>
        </div>
      )}
      {loading && (
        <div className="absolute h-screen pointer-events-none w-screen z-10">
          <Loading />
        </div>
      )}
      {!initialLoading && (
        <div>
          <div className={(sidebarOpen ? 'hidden md:contents ' : 'visible ') + 'bg-white h-screen font-regular z-0'}>
            <Router>
              {isAuthenticated && !loading && <Header />}
              <Routes />
            </Router>
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;
