import React, { FunctionComponent } from 'react';
import RichTextEditor from '../../shared/RichTextEditor';
import ResourcesForm from './ResourcesForm';
import Image from '../../../interfaces/Image';
import { Resources } from '../../../interfaces/category';

interface Props {
  isEditCategory: boolean;
  conPageTitle: string;
  setConPageTitle: (v: string) => void;
  conPageIntroText: string;
  setConPageIntroText: (v: string) => void;
  conPageContent: string;
  setConPageContent: (v: any) => void;
  resourcesList: Resources[];
  setResourcesList: (v: any) => void;
}

const PageContentForm: FunctionComponent<Props> = props => {
  return (
    <div className="ml-10 mr-10 w-6/6">
      <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="addEdiForm">
        <div className="col-span-4 xl:col-span-6">
          {/* Title */}
          <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="contentPageInfo">
            <div className="self-center">
              <span className="text-red text-xl -ml-4">* </span>
              <span>Title:</span>
            </div>
            <div className="col-span-2  xl:col-span-3">
              <input
                data-testid="title"
                className="input-text mb-3"
                value={props.conPageTitle}
                onChange={e => {
                  if (e.target.value.length <= 50) {
                    props.setConPageTitle(e.target.value);
                  }
                }}
              />
            </div>
            <div className="justify-self-start mt-4 xl:col-span-2">{props.conPageTitle?.length}/50</div>
          </div>
          {/* intro text */}
          <div className="col-span-4 xl:col-span-6">
            <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="contentPageInfo">
              <div className="self-center">
                <span className="text-red text-xl -ml-4">* </span>
                <span>Intro Text:</span>
              </div>
              <div className="col-span-2  xl:col-span-3">
                <textarea
                  rows={3}
                  data-testid="productDesc"
                  className="input-text mb-5 "
                  value={props.conPageIntroText}
                  onChange={e => {
                    props.setConPageIntroText(e.target.value);
                  }}
                />
              </div>
              <div className="justify-self-start mt-4 xl:col-span-2">{props.conPageIntroText?.length}</div>
            </div>
          </div>
          {/* content  */}
          <div className="col-span-4 xl:col-span-6">
            <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="contentPageInfo">
              <div>
                <span className="text-red text-xl -ml-4">* </span>
                <span>Content:</span>
              </div>
              <div className="col-span-2  xl:col-span-3">
                <RichTextEditor textEditorContent={props.conPageContent} setTextEditorContent={props.setConPageContent} />
              </div>
            </div>
          </div>
          {/* resources  */}
          <div className="col-span-4 xl:col-span-6 mt-3">
            <ResourcesForm resourcesList={props.resourcesList} setResourcesList={props.setResourcesList} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageContentForm;
