import axios from 'axios';
import React, { FunctionComponent, useRef, useState } from 'react';
import UploadComponent from '../../shared/custom_components/UploadComponent';
import { Resources } from '../../../interfaces/category';
import { toast } from 'react-toastify';

interface Props {
  componentId: number;
  thisfile?: Resources;
  browseResource: (addResource: Resources) => void;
}

const UploadResource: FunctionComponent<Props> = ({ componentId, thisfile, browseResource }) => {
  const [fileName, setfileName] = useState('');
  const handleChange = (e: any) => {
    const currentFile = e.target.files[0];
    if (currentFile) {
      // check if the file is PDF (we only support pdf files here)
      const type = currentFile.name.split('.').pop();
      if (type !== 'pdf') {
        toast.error('Only pdf files are allowed - *.pdf');
        return;
      }
      if (currentFile.size <= 10000000) {
        setfileName(e.target.files[0].name);
        axios.get(`https://10dcilxdki.execute-api.eu-north-1.amazonaws.com/uploads`).then((res: any) => {
          const addResourceItem = {
            type: type,
            resourceUrl: res.data.uploadURL,
            name: currentFile.name.length < 40 ? currentFile.name : currentFile.name.substring(0, 40),
            fileName: currentFile.name,
            resourceKey: res.data.Key,
            fileObject: currentFile,
            componentId: componentId,
          } as Resources;
          browseResource(addResourceItem);
        });
      } else {
        return;
      }
    }
  };

  return (
    <UploadComponent
      componentId={componentId}
      thisfile={thisfile}
      fileName={fileName}
      handleChange={handleChange}
      customStyle={true}
    />
  );
};

export default UploadResource;
