import React, { FunctionComponent } from 'react';

export type ModalProps = {
  additionalText?: string;
  confirmBtnText: string;
  modalTitle: string;
  modalText?: string;
  modalJSX?: JSX.Element[];
  showCancel: boolean;
  isNotification?: boolean;
  closeShowModal: () => void;
  confirmAction: () => void;
};

const Modal: FunctionComponent<ModalProps> = (props: ModalProps) => {
  return (
    <>
      <div
        data-testid="modal"
        className="justify-end items-center flex  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className={`relative ${props.isNotification && props.isNotification === true ? 'w-3/4' : ''} my-4 mx-auto max-w-lg`}>
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-xl font-bold">{props.modalTitle}</h3>
              <button
                data-testid="close-button"
                className="p-1 z-50 ml-auto rounded-lg -mt-2 bg-transparent border text-black opacity-3 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => props.closeShowModal()}
              >
                <span className="bg-transparent -mt-1 text-black px-1 h-7 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div
              className={`${
                props.isNotification && props.isNotification === true ? 'flex items-center' : 'relative'
              } p-6 flex-auto justify-center`}
            >
              <div className="my-4 text-blueGray-500 text-lg leading-relaxed font-regular">
                {props.modalText &&
                  props.modalText.split('\n').map(function (item) {
                    return (
                      <div key={item}>
                        <span>{item}</span>
                        <br />
                      </div>
                    );
                  })}
                {props.modalJSX && (
                  <div>
                    {props.modalJSX}
                    <br />
                  </div>
                )}
              </div>
              {props.additionalText && props.additionalText.length > 0 && (
                <div className="my-4 text-blueGray-500 text-lg leading-relaxed font-regular">{props.additionalText}</div>
              )}
            </div>
            {/*footer*/}
            <div
              className={`${
                props.showCancel ? 'justify-end' : 'justify-center'
              } flex items-center p-6 border-t border-solid border-blueGray-200 rounded-b`}
            >
              {props.showCancel && (
                <button
                  data-testid="cancel-button"
                  className="text-gray-600  background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => props.closeShowModal()}
                >
                  CANCEL
                </button>
              )}
              <button
                data-testid="confirm-button"
                className="bg-emerald-500  text-gray-800 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  props.confirmAction();
                  props.closeShowModal();
                }}
              >
                {props.confirmBtnText}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default Modal;
