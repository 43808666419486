import { AxiosResponse } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import { CategoryType } from '../../interfaces/category';
import Category from './Category';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATHS } from '../../utils/constants/route_paths';
import ModalDialog from '../shared/Modal';
import { stringify } from 'postcss';

const Cms: FunctionComponent = () => {
  const history = useHistory();
  const [axiosApiInstance] = useInterceptor(true);
  const [categories, setCategories] = useState([] as CategoryType[]);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [deleteItem, setDeleteItem] = useState({} as CategoryType);

  //get all categories
  const getAllCategories = () => {
    axiosApiInstance.get(`/cms/all-categories`).then(
      (res: AxiosResponse) => {
        setCategories(res.data as CategoryType[]);
      },
      () => {
        toast.error('No available zones');
      }
    );
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  //open the category form so the user can add subcategory or
  // content page under the chosen category
  const onAddClick = (item: CategoryType) => {
    history.push(`${ROUTE_PATHS.ADDSUBCATEGORYORCONTENT}/${item.id}`);
  };

  // edits the category status
  const onEditStatus = async (item: CategoryType) => {
    const payload: { categoryId: string; active: boolean } = { categoryId: item.id, active: !item.active };
    try {
      axiosApiInstance.put(`/cms/category/status/${item?.id}`, JSON.stringify(payload)).then((res: AxiosResponse) => {
        setCategories(res.data as CategoryType[]);
      });
    } catch (err) {
      toast.error('Something went wrong');
    }
  };

  //view page content from the list of categories
  const onViewClick = (item: CategoryType) => {
    let tab = window.open('about:blank', '_blank');
    if (tab && item.pageContent?.content) {
      tab.document.write(item.pageContent?.content); // where 'html' is a variable containing your HTML
      tab.document.close(); // to finish loading the page
    }
  };

  //edit existing category or page content from the list of categories
  const onEditClick = (item: CategoryType) => {
    history.push(`${ROUTE_PATHS.EDITCATEGORY}/${item.id}/${item.parentId}`);
  };

  //delete existing category from the list of categories
  const onDeleteClick = async () => {
    if (deleteItem) {
      try {
        const deletedItemResponse = await axiosApiInstance.delete(`/cms/${deleteItem.id}`);
        if (deletedItemResponse?.data && deletedItemResponse?.data?.deleted) {
          getAllCategories();
        }
      } catch (error) {
        console.log('handle error on Delete');
      }
    }
  };

  //open popover for adding new category
  const addNewCategory = () => {
    history.push(ROUTE_PATHS.ADDCATEGORY);
  };

  const categoryTree: any[] = [];
  let treeStructure: any = {};

  // recursive function for setting and drawing the tree structure
  const iterate = (category: CategoryType) => {
    // case when this is the main tree element (parent of all categories)
    if (!category.parentId) {
      treeStructure[category.id] = [];
      // case when the element is some descendent
    } else {
      treeStructure[category.id] = [...treeStructure[category.parentId]];
    }

    categoryTree.push(
      <Category
        categoryItem={category}
        treeSchema={[...treeStructure[category.id]]}
        key={category.id}
        setShowDeleteModal={setShowDeleteModal}
        setDeleteItem={setDeleteItem}
        onEditClick={onEditClick}
        onViewClick={onViewClick}
        onAddClick={onAddClick}
        onEditStatus={onEditStatus}
      />
    );
    if (category.children && category.children.length > 0) {
      treeStructure[category.id].push(false);
      for (let i = 0; i < category.children.length; i++) {
        const parentId = category.id;
        category.children[i].parentId = parentId;

        let arrayLength = treeStructure[category.id].length;

        //logic for drawing the tree nodes
        // if it is the last child then set false ( it is the last node of the tree,
        // stop drawing the tree after this element )
        if (i + 1 === category.children.length) {
          treeStructure[category.id][arrayLength - 1] = false;
          // there is another node in the tree after this element
          // set true to continue with the tree drawing
        } else {
          treeStructure[category.id][arrayLength - 1] = true;
        }

        iterate(category.children[i]);
      }
    }
  };

  // construct the whole tree structure
  for (let i = 0; i < categories.length; i++) {
    let drawTree = true;
    if (categories[i].children.length === 0 || i + 1 === categories.length) {
      drawTree = false;
    }
    treeStructure = [];
    iterate(categories[i]);
  }

  return (
    <div className="h-screen w-screen bg-white place-items-center px-10 py-12" data-testid="cms">
      {/* page title */}
      <div className="flex justify-start mb-3">Content Management System</div>
      <hr className="mb-6" />
      <div className="flex justify-start mb-3">Status / Category / Options</div>
      {/* category tree structure */}
      {categoryTree}
      <div className="flex justify-start mb-3">
        <a href="#" data-testid="newCategory" onClick={() => addNewCategory()} className={'btn-main'}>
          + Add a Category
        </a>
      </div>
      {/* confirmation modal when deleting some item */}
      {showDeleteModal && (
        <ModalDialog
          modalText={`Are you sure you wish to delete ${deleteItem.name} from the Category list? \n\n Warring: \n By deleting the selected category all descendants of this category will be also deleted!!!`}
          modalTitle={'DELETE CATEGORY'}
          closeShowModal={() => {
            setShowDeleteModal(false);
          }}
          confirmAction={onDeleteClick}
          confirmBtnText={'Delete'}
          showCancel={true}
        />
      )}
    </div>
  );
};

export default Cms;
