/**
 * Copyrights 2021 Playtika Ltd., all rights reserved to Playtika Ltd.
 * privacy+e17bb14d-edc1-4d26-930d-486fcc1ab8fe@playtika.com
 */

import create, { State } from 'zustand';
import ProductCatalogItem from '../interfaces/product';
import { Site } from '../interfaces/site';
import { TimeZone } from '../interfaces/timezone';
import TableColProperty from '../modules/shared/interfaces/TableColProperty';

interface productStore extends State {
  editProduct: ProductCatalogItem | undefined;
  sites: Site[];
  zones: TimeZone[];
  colPropertyList: TableColProperty[]
  setcolPropertyList: (apiValue: string) => void;
  setEditProduct: (ProductCatalogItem: ProductCatalogItem) => void;
  setSites: (sites: Site[]) => void;
  setZones: (zones: TimeZone[]) => void;
}

// zustand store that returns global setting for the application
const productSettings = create<productStore>(
  (set: any): productStore => ({
    editProduct: undefined,
    sites: [] as Site[],
    zones: [] as TimeZone[],
    colPropertyList: [
      { title: 'Product ID', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'id', isVisible: true },
      { title: 'Product Name', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'name', isVisible: true },
      { title: 'Product Description', colMaxWidth: 'auto', colMinWidth: '100px', apiValue: 'description', isVisible: true },
      { title: 'Additional Info', colMaxWidth: 'auto', colMinWidth: '150px', apiValue: 'additionalInfo', isVisible: true },
      { title: 'Status', colMaxWidth: 'auto', colMinWidth: '100px', apiValue: 'status', isVisible: true },
      { title: 'Published', colMaxWidth: 'auto', colMinWidth: '90px', apiValue: 'published', isVisible: true },
      { title: 'Property #1 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName1', isVisible: true },
      { title: 'Property #1 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue1', isVisible: true },
      { title: 'Property #2 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName2', isVisible: true },
      { title: 'Property #2 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue2', isVisible: true },
      { title: 'Property #3 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName3', isVisible: true },
      { title: 'Property #3 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue3', isVisible: true },
      { title: 'Property #4 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName4', isVisible: true },
      { title: 'Property #4 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue4', isVisible: true },
      { title: 'Property #5 Name', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyName5', isVisible: true },
      { title: 'Property #5 Value', colMaxWidth: '70px', colMinWidth: 'auto', apiValue: 'propertyValue5', isVisible: true },
      { title: 'Price', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'price', isVisible: true },
      { title: 'Sale Price', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'priceOnSale', isVisible: true },
      { title: 'Delivery Time', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'deliveryTime', isVisible: true },
      { title: 'Images', colMaxWidth: 'auto', colMinWidth: '150px', apiValue: 'thumbnail', isVisible: true },
      { title: 'Relevant for', colMaxWidth: 'auto', colMinWidth: '150px', apiValue: 'availableInSites', isVisible: true },
      { title: '"New" Label', colMaxWidth: 'auto', colMinWidth: 'auto', apiValue: 'newProduct', isVisible: true },
      { title: '"Must Have" Label', colMaxWidth: 'auto', colMinWidth: '100px', apiValue: 'mustHaveLabel', isVisible: true },
      { title: 'Action', colMaxWidth: 'auto', colMinWidth: '90px', apiValue: 'action_items', isVisible: true }
    ],
    setcolPropertyList: (apiValue: string) => set((state: any) => ({
      colPropertyList: state.colPropertyList.map((column: any) => {
        if (column.apiValue === apiValue) {
          return {...column, isVisible: !column.isVisible}
        } else {
          return {...column}
        }
      })
    })),
    setEditProduct: (_editProduct: ProductCatalogItem) => set(() => ({ editProduct: _editProduct })),
    setSites: (_sites: Site[]) => set(() => ({ sites: _sites})),
    setZones: (_zones: TimeZone[]) => set(() => ({ zones: _zones }))
  })
);

export default productSettings;
