import React, { FunctionComponent } from 'react';
import '../../styles/shared/loader.css';
import loadingText from './../../assets/images/loadingText.png';
import loadingImg from './../../assets/images/loadingImg.png';

const LoadingScreen: FunctionComponent = () => {
    return (
        <div className="bg-white w-full h-full" data-testid="loadingScreen">
            <div  className="grid grid-row-1 place-items-center" data-testid="loadingLayout">
            <img
                    src={loadingImg}
                    alt="Loading image"
                    className="mt-20 h-24 md:h-36 w-48"
                />
            <img
                    src={loadingText}
                    alt="Loading text"
                    className="mt-5 h-24 md:h-36 w-48"
                />
                <span className="text-xs">Few More Seconds and you are there!</span>
            </div>
        </div>
    );
};

export default LoadingScreen;
