import React, { FunctionComponent, useEffect } from 'react';
//components
import DropDown from '../../shared/custom_components/DropDown';

export type ProductProps = {
    productCost: number | string;
    setProductCost: (v: number | string) => void;
    priceOnSale: number | string;
    setPriceOnSale: (v: number | string) => void;
    deliveryTime: number;
    setDeliveryTime: (v: number) => void;
    newProduct: boolean;
    setNewProduct: (v: boolean) => void;
    mustHave: boolean;
    setMustHave: (v: boolean) => void;
};

const ProductOrder: FunctionComponent<ProductProps> = (props: ProductProps) => {

    return (
        <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="productOrderInfo">
            <div className="self-center">
                <span className="text-red text-xl -ml-4">* </span>
                Cost (Original):
            </div>
            <div className="xl:col-span-2">
                <input
                    data-testid="productCost"
                    className="input-text mb-6 mt-4 maxwidth-input"
                    value={props.productCost}
                    onBlur={(e) => props.setProductCost(!e.target.value.length ? 0 : parseFloat(e.target.value).toFixed(2))}
                    onChange={e => {
                        props.setProductCost(e.target.value.length < 1 ? "" : (e.target.value));
                    }}
                />
            </div>
            <div className="justify-self-start mt-6  xl:col-span-1"></div>
            <div className="justify-self-start mt-6  xl:col-span-2">(In PlaytiCoins, e.g. 10.00)</div>
            <div className="self-center">Cost (On Sale):</div>
            <div className="xl:col-span-2">
                <input
                    data-testid="priceOnSale"
                    className="input-text mb-5 maxwidth-input"
                    value={props.priceOnSale}
                    onBlur={(e) => props.setPriceOnSale(!e.target.value.length ? 0 : parseFloat(e.target.value).toFixed(2))}
                    onChange={e => {
                        props.setPriceOnSale(e.target.value.length < 1 ? "" : (e.target.value));
                    }}
                />
            </div>
            <div className="justify-self-start mt-4 xl:col-span-1"></div>
            <div className="justify-self-start mt-4 xl:col-span-2">(In PlaytiCoins, e.g. 5.00)</div>
            <div className="self-center mb-4">
                <span className="text-red text-xl -ml-4">* </span>
                Delivery Time:
            </div>
            <div className="xl:col-span-2">
                <div className="dropdown w-full" data-testid="ddl_deliveryTime">
                    <DropDown
                        value={props.deliveryTime.toString()}
                        placeholder="Select property"
                        onChange={(v: string) => {
                            props.setDeliveryTime(parseInt(v));
                        }}
                        options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13',
                            '14', '15', '16', '17', '18', '19', '20', '21']}
                    />
                </div>
            </div>
            <div className="justify-self-start mt-4 xl:col-span-1"></div>
            <div className="justify-self-start mt-4 xl:col-span-2">(Days)</div>
            <div className="self-center mb-4">Is &quot;New&quot;?</div>
            <div className="justify-self-start mt-4 xl:col-span-1">
                <label className={`checkbox-container justify-start`}>
                    <input
                        data-testid="chk_newProduct"
                        className="mr-4 leading-tight"
                        type="checkbox"
                        checked={props.newProduct}
                        onChange={() => {
                            props.setNewProduct(!props.newProduct);
                        }}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
            <div className="justify-self-start mt-4"></div>
            <div className="justify-self-start mt-4 xl:col-span-3"></div>
            <div className="self-center mb-4">Is &quot;Must Have&quot;?</div>
            <div className="justify-self-start mt-4 xl:col-span-1">
                <label className={`checkbox-container justify-start`}>
                    <input
                        data-testid="chk_mustHave"
                        className="mr-4 leading-tight"
                        type="checkbox"
                        checked={props.mustHave}
                        onChange={() => {
                            props.setMustHave(!props.mustHave);
                        }}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
            <div className="justify-self-start mt-4"></div>
            <div className="justify-self-start mt-4 xl:col-span-3"></div>
        </div>
    );
};
export default ProductOrder;
