import axios, { AxiosResponse } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import Image from '../../interfaces/Image';
import { Site } from '../../interfaces/site';
//store
import productSettings from '../../stores/productSettings';
import useSettings from '../../stores/settings';
import { ROUTE_PATHS } from '../../utils/constants/route_paths';
//components
import ImageList from './AddEditProduct/ImageList';
import ProductInfo from './AddEditProduct/ProductInfoDetails';
import ProductOrder from './AddEditProduct/ProductOrderDetails';
import ProductProperites from './AddEditProduct/PropertyList';

interface Props {
  isEditProduct: boolean;
  isDuplicate?: boolean;
}
interface Params {
  productId: string | undefined;
}

const AddEditProduct: FunctionComponent<Props> = ({ isEditProduct, isDuplicate }) => {
  const history = useHistory();
  const [id, setId] = useState('0');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [productStatus, setProductStatus] = useState('Available');
  // Property 1
  const [prop1Name, setProp1Name] = useState('');
  const [prop1Value, setProp1Value] = useState('');
  // Property 2
  const [prop2Name, setProp2Name] = useState('');
  const [prop2Value, setProp2Value] = useState('');
  // Property 3
  const [prop3Name, setProp3Name] = useState('');
  const [prop3Value, setProp3Value] = useState('');
  // Property 4
  const [prop4Name, setProp4Name] = useState('');
  const [prop4Value, setProp4Value] = useState('');
  // Property 5
  const [prop5Name, setProp5Name] = useState('');
  const [prop5Value, setProp5Value] = useState('');
  const [productCost, setProductCost] = useState<any>(0);
  const [priceOnSale, setPriceOnSale] = useState<any>(0);
  const [deliveryTime, setDeliveryTime] = useState(1);
  const [newProduct, setNewProduct] = useState(true);
  const [mustHave, setMustHave] = useState(false);
  const [published, setPublished] = useState(false)
  const [prevPublishedValue, setPrevPublishedValue] = useState(false)
  const [imagesList, setImagesList] = useState([] as Image[]);
  const [availableInSites, setAvailableInSites] = useState([] as Site[]);
  const { productId } = useParams<Params>();
  const [errorMsg, setErrorMsg] = useState('');
  const { editProduct, sites, setEditProduct, setSites } = productSettings();
  const [axiosApiInstance] = useInterceptor();
  const [apiGatewayApiInstance] = useInterceptor(true);

  useEffect(() => {
    if (isEditProduct || isDuplicate) {
      axiosApiInstance.get(`/products/${productId}`).then(
          (res: any) => {
            setEditProduct(res.data);
          },
          () => {
            toast.error('Product can not be loaded');
          }
      );
    }

    //get all available sites
    apiGatewayApiInstance.get(`/settings/sites`).then(
        (res: any) => {
          setSites(res.data as Site[]);
        },
        () => {
          toast.error('No available sites');
        }
    );
  }, []);
  useEffect(() => {
    if ((isEditProduct || isDuplicate) && editProduct !== undefined) {
      setId(editProduct.id);
      setProductName(editProduct.name);
      setProductDescription(editProduct.description);
      setAdditionalInfo(editProduct.additionalInfo);
      setAvailableInSites(editProduct.availableInSites);
      const setImageList = [] as Image[];
      editProduct.images.forEach((image: Image, index: number) => {
        image.componentId = index + 1;
        setImageList.push(image);
      });
      setImagesList(setImageList);
      //properties
      const key_arr: string[] = Object.keys(editProduct.properties);
      const val_arr: string[] = Object.values(editProduct.properties);
      if (key_arr[0] && val_arr[0]) {
        setProp1Name(key_arr[0]);
        setProp1Value(val_arr[0]);
      }
      if (key_arr[1] && val_arr[1]) {
        setProp2Name(key_arr[1]);
        setProp2Value(val_arr[1]);
      }
      if (key_arr[2] && val_arr[2]) {
        setProp3Name(key_arr[2]);
        setProp3Value(val_arr[2]);
      }
      if (key_arr[3] && val_arr[3]) {
        setProp4Name(key_arr[3]);
        setProp4Value(val_arr[3]);
      }
      if (key_arr[4] && val_arr[4]) {
        setProp5Name(key_arr[4]);
        setProp5Value(val_arr[4]);
      }
      setProductCost(editProduct.price);
      setPriceOnSale(editProduct.priceOnSale);
      setDeliveryTime(editProduct.deliveryTime);
      setNewProduct(editProduct.newProduct);
      setMustHave(editProduct.mustHaveLabel);
      setProductStatus(editProduct.status);
      setPublished(editProduct.published)
      setPrevPublishedValue(editProduct.published)
    }
  }, [editProduct]);
  const saveProduct = async () => {
    let errorMsg = '';
    if (
        productName === '' ||
        productDescription === '' ||
        productStatus === '' ||
        productCost === ( 0 || "0.00") ||
        deliveryTime.toString() === '' ||
        availableInSites.length === 0
    ) {
      errorMsg = 'Please fill in all required fields!';
      setErrorMsg(errorMsg);
      return;
    }
    let hasImageUpload = false;
    if (imagesList.filter((c: Image) => c.componentId && !c.id).length > 0) {
      const countImageUpload = imagesList.filter((c: Image) => !c.id).length;
      let imageUploaded = 0;
      imagesList.forEach(async (currentImage: Image) => {
        if (currentImage.componentId && !currentImage.id) {
          hasImageUpload = true;
          axios
              .put(currentImage.imageUrl, currentImage.fileObject, {
                headers: {
                  'Content-Type': currentImage.fileObject?.type   //To be reverted back to required when the API for perks is applied
                },
              })
              .then(
                  (res: AxiosResponse) => {
                    // Only for the first main image i need await, because for load i need it on the main page
                    if (res.status === 200) {
                      imageUploaded += 1;
                      if (countImageUpload === imageUploaded) {
                        toast.success('Product saved successfuly');
                        history.push(ROUTE_PATHS.PRODUCT);
                      } else {
                        return;
                      }
                    }
                  },
                  () => {
                    toast.error('Something went wrong');
                  }
              );
        }
      });
    }
    const mainImage = imagesList.filter((c: Image) => c.mainImage === true);
    const defaultMainImage = mainImage.length ? null : imagesList.filter((c: Image) => c.mainImage !== true)[0];
    if (defaultMainImage) {
      defaultMainImage.mainImage = true;
    }
    const mainImageUrl = mainImage.length
        ? `https://d3nioszw1lcabk.cloudfront.net/${mainImage[0].imageKey}`
        : `https://d3nioszw1lcabk.cloudfront.net/${defaultMainImage?.imageKey}`;
    const productObject = {
      name: productName,
      description: productDescription,
      additionalInfo: additionalInfo,
      properties: {
        [prop1Name]: prop1Value,
        [prop2Name]: prop2Value,
        [prop3Name]: prop3Value,
        [prop4Name]: prop4Value,
        [prop5Name]: prop5Value,
      },
      price: parseFloat(productCost),
      priceOnSale: parseFloat(priceOnSale),
      deliveryTime: deliveryTime,
      newProduct: newProduct,
      mustHaveLabel: mustHave,
      images: !isDuplicate
          ? imagesList
          : imagesList.map(img => {
            return {
              imageUrl: img.imageUrl,
              name: img.name,
              mainImage: img.mainImage,
              imageUploadUrl: img.imageUploadUrl,
              imageKey: img.imageKey,
              fileObject: img.fileObject,
              componentId: img.componentId,
            };
          }),
      availableInSites: availableInSites,
      status: productStatus,
      thumbnail: mainImageUrl,
    };
    let updatePublished = {
      productId: editProduct?.id,
      published
    }
    if (isEditProduct) {
      try {
        await axiosApiInstance.put(`/products/${editProduct?.id}`, JSON.stringify(productObject));
        if (prevPublishedValue !== published){
         await apiGatewayApiInstance.put('product/product-publish', JSON.stringify(updatePublished))
        }
      }
      catch (err) {
        toast.error('Something went wrong');
      }
    } else {
      try {
        await axiosApiInstance.post(`/products/`, JSON.stringify(productObject));
      } catch (err) {
        toast.error('Something went wrong');
      }
    }
    if (!hasImageUpload) {
      toast.success('Product saved successfuly');
      history.push(ROUTE_PATHS.PRODUCT);
    }
  };
  const productAvailableCheck = (item: Site) => {
    let availableProductSiteList = [...availableInSites];
    if (availableProductSiteList.filter(c => c.id === item.id).length > 0) {
      availableProductSiteList = availableProductSiteList.filter(c => c.id !== item.id);
    } else {
      availableProductSiteList.push(item);
    }
    setAvailableInSites(availableProductSiteList);
  };
  const checkAll = () => {
    const allAreSelected = availableInSites.length === sites.filter((c: Site) => c.id !== '0').length;
    if (allAreSelected) {
      setAvailableInSites([]);
    } else {
      setAvailableInSites(sites.filter((c: Site) => c.id !== '0'));
    }
  };

  return (
      <div data-testid="addEditProduct">
        <div className="mt-5">
        <span className="ml-10 mr-10 font-bold text-gray-700">
          {isEditProduct ? 'Edit Product' : isDuplicate ? 'Duplicate Product' : 'Add NEW Product'}
        </span>
        </div>
        <div className="mb-10 ml-10 mr-10 border-t-2 border-gray-400"></div>
        <div className="ml-10 mr-10 w-6/6">
          <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="addEdiForm">
            <div className={(isEditProduct ? 'visible ' : 'hidden ') + 'self-center mb-2'}>
              <span>Product ID:</span>
            </div>
            <div className={(isEditProduct ? 'visible ' : 'hidden ') + 'col-span-3  xl:col-span-5 mb-2'} data-testid="productId">
              <span>{id}</span>
            </div>

            <div className="col-span-4 xl:col-span-6">
              <ProductInfo
                  productName={productName}
                  setProductName={setProductName}
                  productDescription={productDescription}
                  setProductDescription={setProductDescription}
                  additionalInfo={additionalInfo}
                  setAdditionalInfo={setAdditionalInfo}
                  productStatus={productStatus}
                  setProductStatus={setProductStatus}
                  published={published}
                  setPublished={setPublished}
                  isEditProduct={isEditProduct}
              />
            </div>

            <div className="col-span-4 xl:col-span-6">
              <ProductProperites
                  prop1Name={prop1Name}
                  prop1Value={prop1Value}
                  setProp1Name={setProp1Name}
                  setProp1Value={setProp1Value}
                  prop2Name={prop2Name}
                  prop2Value={prop2Value}
                  setProp2Name={setProp2Name}
                  setProp2Value={setProp2Value}
                  prop3Name={prop3Name}
                  prop3Value={prop3Value}
                  setProp3Name={setProp3Name}
                  setProp3Value={setProp3Value}
                  prop4Name={prop4Name}
                  prop4Value={prop4Value}
                  setProp4Name={setProp4Name}
                  setProp4Value={setProp4Value}
                  prop5Name={prop5Name}
                  prop5Value={prop5Value}
                  setProp5Name={setProp5Name}
                  setProp5Value={setProp5Value}
              />
            </div>
            <div className="col-span-4 xl:col-span-6">
              <ProductOrder
                  productCost={productCost}
                  setProductCost={setProductCost}
                  priceOnSale={priceOnSale}
                  setPriceOnSale={setPriceOnSale}
                  deliveryTime={deliveryTime}
                  setDeliveryTime={setDeliveryTime}
                  newProduct={newProduct}
                  setNewProduct={setNewProduct}
                  mustHave={mustHave}
                  setMustHave={setMustHave}
              />
            </div>
            <div className="col-span-4 xl:col-span-6">
              <ImageList imagesList={imagesList} setImagesList={setImagesList} />
            </div>
            <div className="w-3/5">
              <span className="text-red text-xl -ml-4">* </span>
              <span>Product will be available in:</span>
            </div>
            <div className="w-3/5 col-span-2 border rounded-sm border-gray-700 mt-2 ">
              <div className="w-full m-2">
                <label className={`checkbox-container inline-block custom-for-sites `}>
                  <input
                      data-testid="checkAll"
                      className="mr-2"
                      type="checkbox"
                      checked={availableInSites.length === sites.filter(c => c.id !== '0').length}
                      onChange={() => {
                        checkAll();
                      }}
                  />
                  <span className="checkmark"></span>
                </label>
                <span className="mr-2">All Offices</span>
                <div className="-ml-1 mr-3 mt-2 border-t border-gray-600" />
              </div>
              {sites
                  .filter(c => c.id !== '0')
                  .map((item: Site, index: number) => (
                      <div key={`z_${item.id}`} className="m-2">
                        <label className={`checkbox-container inline-block custom-for-sites `}>
                          <input
                              data-testid={`check-item-${index}`}
                              className="mr-2"
                              type="checkbox"
                              checked={availableInSites.filter(c => c.id === item.id).length > 0}
                              onChange={() => {
                                productAvailableCheck(item);
                              }}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <span className="mr-2">{item.name}</span>
                      </div>
                  ))}
            </div>
          </div>
        </div>
        {errorMsg.length > 0 && (
            <div className="flex justify-center mt-10 -mb-8 font-semibold">
          <span data-testid="errorSubmit" className="text-red mx-5">
            {errorMsg}
          </span>
            </div>
        )}
        <div className="h-20">
          <div className="flex justify-center mt-10">
            <button
                data-testid="eventSubmit"
                className={'btn-main'}
                onClick={() => {
                  saveProduct();
                }}
            >
              Upload Images & Submit
            </button>
          </div>
        </div>
      </div>
  );
};
export default AddEditProduct;