import React, { FunctionComponent } from 'react';
import Image from '../../../interfaces/Image';
//components
import UploadImage from '../../shared/custom_components/UploadImage';

export type ImageProps = {
  imagesList: Image[];
  setImagesList: (imageList: Image[]) => void;
};

const ImageUploadList: FunctionComponent<ImageProps> = (props: ImageProps) => {
  const addImageItem = (addImage: Image) => {
      let imageList = [...props.imagesList];
      imageList = imageList.filter(c => c.componentId !== addImage.componentId);

      imageList.push(addImage);
      props.setImagesList(imageList);
  };

  const removeImageItem = (removeImage: any) => {
    if (removeImage) {
      props.setImagesList(props.imagesList.filter(image => image.imageKey !== removeImage.imageKey))
    }
  }
  return (
    <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6">
      <div className="self-center" data-testid="imagesList">
        <span className="text-red text-xl -ml-4">* </span>
        <span>Images: (Main)</span>
        {/* <span className="ml-14 xl:ml-16 2xl:ml-56 self-center mb-3"> #1 </span> */}
      </div>
      <div className="col-span-2  xl:col-span-3" data-testid="imageUpload_1">
        <span className="-ml-6 mt-4 absolute"> #1 </span>
        <div className="align-middle">
          <UploadImage
            removeImageItem={removeImageItem}
            browseImage={addImageItem}
            componentId={1}
            thisfile={props.imagesList.filter(c => c.componentId === 1)[0]}
          />
        </div>
      </div>
      <div className="justify-self-start mt-4 xl:col-span-2">(Upload in Y format, max of 5MB)</div>
      <div className="self-center"></div>
      <div className="col-span-2 xl:col-span-3" data-testid="imageUpload_2">
        <span className="-ml-6 mt-4 absolute">#2</span>
        <div className="align-middle">
          <UploadImage
            removeImageItem={removeImageItem}
            browseImage={addImageItem}
            componentId={2}
            thisfile={props.imagesList.filter(c => c.componentId === 2)[0]}
          />
        </div>
      </div>
      <div className="justify-self-start mt-4 xl:col-span-2">(Upload in Y format, max of 5MB)</div>
      <div className="self-center"></div>
      <div className="col-span-2 xl:col-span-3" data-testid="imageUpload_3">
        <span className="-ml-6 mt-4 absolute">#3</span>
        <div className="align-middle">
          <UploadImage
            removeImageItem={removeImageItem}
            browseImage={addImageItem}
            componentId={3}
            thisfile={props.imagesList.filter(c => c.componentId === 3)[0]}
          />
        </div>
      </div>
      <div className="justify-self-start mt-4 xl:col-span-2">(Upload in Y format, max of 5MB)</div>
      <div className="self-center"></div>
      <div className="col-span-2  xl:col-span-3" data-testid="imageUpload_4">
        <span className="-ml-6 mt-4 absolute">#4</span>
        <div className="align-middle">
          <UploadImage
            removeImageItem={removeImageItem}
            browseImage={addImageItem}
            componentId={4}
            thisfile={props.imagesList.filter(c => c.componentId === 4)[0]}
          />
        </div>
      </div>
      <div className="justify-self-start mt-4 xl:col-span-2">(Upload in Y format, max of 5MB)</div>
      <div className="self-center"></div>
      <div className="col-span-2  xl:col-span-3" data-testid="imageUpload_5">
        <span className="-ml-6 mt-4 absolute">#5</span>
        <div className="align-middle">
          <UploadImage
            removeImageItem={removeImageItem}
            browseImage={addImageItem}
            componentId={5}
            thisfile={props.imagesList.filter(c => c.componentId === 5)[0]}
          />
        </div>
      </div>
      <div className="justify-self-start mt-4 xl:col-span-2">(Upload in Y format, max of 5MB)</div>
    </div>
  );
};
export default ImageUploadList;
