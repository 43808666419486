import React, { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../../auth/interceptor';
//components
import DropDown from '../../shared/custom_components/DropDown';

export type ProductProps = {
  prop1Name: string;
  prop1Value: string;
  setProp1Name: (v: string) => void;
  setProp1Value: (v: string) => void;
  prop2Name: string;
  prop2Value: string;
  setProp2Name: (v: string) => void;
  setProp2Value: (v: string) => void;
  prop3Name: string;
  prop3Value: string;
  setProp3Name: (v: string) => void;
  setProp3Value: (v: string) => void;
  prop4Name: string;
  prop4Value: string;
  setProp4Name: (v: string) => void;
  setProp4Value: (v: string) => void;
  prop5Name: string;
  prop5Value: string;
  setProp5Name: (v: string) => void;
  setProp5Value: (v: string) => void;
};

const ProductProperites: FunctionComponent<ProductProps> = (props: ProductProps) => {
  const [axiosApiInstance] = useInterceptor();
  const [productProperites, setProductProperites] = useState([]);
  const [produCtPropValues, setProduCtPropValues] = useState(['']);
  useEffect(() => {
    //get all product properties
    axiosApiInstance.get(`/products/properties`).then(
      res => {
        setProductProperites(res.data);
      },
      () => {
        toast.error('No available products');
      }
    );
  }, []);
  useEffect(() => {
    const values: string[] = Object.values(productProperites);
    let color = undefined;
    Object.keys(productProperites).forEach((el, ind) => {
      if (el.toLowerCase() === 'color') {
        color = ind;
      }
    });
    if (color !== undefined && values.length > 0) {
      const obj = Object.keys(JSON.parse(values[color].split('/').join(''))).join('/');
      if (obj) {
        values[color] = obj;
      }
    }
    setProduCtPropValues(values);
  }, [productProperites]);
  return (
    <div className="grid gap-1 xl:gap-2 grid-cols-4 xl:grid-cols-6" data-testid="propertyList">
      <div className="self-center" data-testid="property_1">
        Property #1:
      </div>
      <div className="dropdown w-full  xl:col-span-2" data-testid="property-1-dropdown">
        <DropDown
          value={props.prop1Name}
          placeholder="Select property"
          onChange={(v: string) => {
            props.setProp1Name(v);
            props.setProp1Value(produCtPropValues[Object.keys(productProperites).indexOf(v)]);
          }}
          options={Object.keys(productProperites)}
        />
      </div>
      <div className="xl:col-span-2">
        <div className="flex mt-4">
          {props.prop1Value && (
            <div>
              <span>List of values:</span>
              <span className="ml-4">{props.prop1Value}</span>
            </div>
          )}
        </div>
      </div>
      <div className="justify-self-start mt-4 xl:col-span-1"></div>
      <div className="self-center" data-testid="property_2">
        Property #2:
      </div>
      <div className="dropdown w-full xl:col-span-2" data-testid="property-2-dropdown">
        <DropDown
          value={props.prop2Name}
          placeholder="Select property"
          onChange={(v: string) => {
            props.setProp2Name(v);
            props.setProp2Value(produCtPropValues[Object.keys(productProperites).indexOf(v)]);
          }}
          options={Object.keys(productProperites)}
        />
      </div>
      <div className="xl:col-span-2">
        <div className="flex mt-4">
          {props.prop2Value && (
            <div>
              <span>List of values:</span>
              <span className="ml-4">{props.prop2Value}</span>
            </div>
          )}
        </div>
      </div>
      <div className="justify-self-start xl:col-span-1"></div>
      <div className="self-center" data-testid="property_3">
        Property #3:
      </div>
      <div className="dropdown w-full  xl:col-span-2" data-testid="property-3-dropdown">
        <DropDown
          value={props.prop3Name}
          placeholder="Select property"
          onChange={(v: string) => {
            props.setProp3Name(v);
            props.setProp3Value(produCtPropValues[Object.keys(productProperites).indexOf(v)]);
          }}
          options={Object.keys(productProperites)}
        />
      </div>
      <div className="xl:col-span-2">
        <div className="flex mt-4">
          {props.prop3Value && (
            <div>
              <span>List of values:</span>
              <span className="ml-4">{props.prop3Value}</span>
            </div>
          )}
        </div>
      </div>
      <div className="justify-self-start xl:col-span-1"></div>
      <div className="self-center" data-testid="property_4">
        Property #4:
      </div>
      <div className="dropdown w-full xl:col-span-2" data-testid="property-4-dropdown">
        <DropDown
          value={props.prop4Name}
          placeholder="Select property"
          onChange={(v: string) => {
            props.setProp4Name(v);
            props.setProp4Value(produCtPropValues[Object.keys(productProperites).indexOf(v)]);
          }}
          options={Object.keys(productProperites)}
        />
      </div>
      <div className="xl:col-span-2">
        <div className="flex mt-4">
          {props.prop4Value && (
            <div>
              <span>List of values:</span>
              <span className="ml-4">{props.prop4Value}</span>
            </div>
          )}
        </div>
      </div>
      <div className="justify-self-start  xl:col-span-1"></div>
      <div className="self-center" data-testid="property_5">
        Property #5:
      </div>
      <div className="dropdown w-full xl:col-span-2" data-testid="property-5-dropdown">
        <DropDown
          value={props.prop5Name}
          placeholder="Select property"
          onChange={(v: string) => {
            props.setProp5Name(v);
            props.setProp5Value(produCtPropValues[Object.keys(productProperites).indexOf(v)]);
          }}
          options={Object.keys(productProperites)}
        />
      </div>
      <div className="xl:col-span-2">
        <div className="flex mt-4">
          {props.prop5Value && (
            <div>
              <span>List of values:</span>
              <span className="ml-4">{props.prop5Value}</span>
            </div>
          )}
        </div>
      </div>
      <div className="justify-self-start mt-4 xl:col-span-1"></div>
    </div>
  );
};
export default ProductProperites;
