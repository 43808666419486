/**
 * Copyrights 2021 Playtika Ltd., all rights reserved to Playtika Ltd.
 * privacy+e17bb14d-edc1-4d26-930d-486fcc1ab8fe@playtika.com
 */

import create, { State } from 'zustand';
import User from '../interfaces/user';

interface UserSettings extends State {
  user: User | undefined;
  setUser: (user: User) => void;
}

// zustand store that returns global setting for the application
const userSettings = create<UserSettings>(
  (set): UserSettings => ({
    user: undefined,
    setUser: (_user: User) => set(() => ({ user: _user }))
  })
);

export default userSettings;
