/**
 * Copyrights 2021 Playtika Ltd., all rights reserved to Playtika Ltd.
 * privacy+e17bb14d-edc1-4d26-930d-486fcc1ab8fe@playtika.com
 */

import { LogLevel } from '@azure/msal-browser';
import { CacheLocation, Configuration } from 'msal';

// configuration for Microsoft Authentication
export const msalConfig: Configuration = {
    auth: {
        //clientId taken from the application created on azure
        clientId: process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID,
        //tenantId taken from the application created on azure
        authority: 'https://login.microsoftonline.com/' + process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_TENANT_ID,
        knownAuthorities: [],
        //redirect url, specified on the azure app,
        //which is the return url on the application, after microsoft authentication
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL,
        //logout url, the return route on the application, after logging out from azure
        postLogoutRedirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: 'localStorage' as CacheLocation, //location where to store authentication data
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            piiLoggingEnabled: false
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0
    }
} as Configuration;
