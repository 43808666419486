import { AxiosResponse } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useInterceptor } from '../../auth/interceptor';
import GiftType from '../../interfaces/GiftType';
import { UserItem } from '../../interfaces/user';
import DropDown from '../shared/custom_components/DropDown';
import ModalDialog from '../shared/Modal';

export type GivePlayticoinsProps = {
  usersList: UserItem[];
};

const GivePlayticoins: FunctionComponent<GivePlayticoinsProps> = props => {
  const [playticoins, setPlayticoins] = useState(0);
  const [giftTypes, setGiftTypes] = useState([] as GiftType[]);
  const [selectedGiftType, setSelectedGiftType] = useState({} as GiftType);
  const [givePlayticoinsMsg, setGivePlayticoinsMsg] = useState([] as JSX.Element[]);
  const [showPlayticoinsGivenPopup, setShowPlayticoinsGivenPopup] = useState(false);
  const [axiosApiInstance] = useInterceptor();
  useEffect(() => {
    //get all available gift types
    axiosApiInstance.get(`/gifts/gift-types`).then(
      (res: AxiosResponse) => {
        setGiftTypes(res.data as GiftType[]);
      },
      () => {
        toast.error('No gifts available');
      }
    );
  }, []);

  //set selected playticoin type
  // when playticoin type is changed in the dropdown
  const selectGiftType = (type: any | undefined) => {
    if (type) {
      setSelectedGiftType(type);
    }
  };

  // grant gifts to the selected employees
  const grantGift = () => {
    type GiftRequest = {
      typeId: string;
      amount: number;
      giftReceiverId: string[];
      status: string;
    };
    const data: GiftRequest = {
      typeId: selectedGiftType.id,
      amount: playticoins,
      giftReceiverId: props.usersList.filter(user => user.checked).map(u => u.id),
      status: 'pending',
    };
    type GiftSentResponse = {
      amount: number;
      giftCanceledByUserId: string;
      giftReceiverId: string;
      giftSenderId: string;
      id: string;
      status: string;
      type: GiftType;
    };
    axiosApiInstance.post(`/gifts/admin-gift-cards`, data).then(
      res => {
        const msg: JSX.Element[] = [] as JSX.Element[];
        if (res.data.length > 0) {
          msg.push(
            <div key="0" className="mb_2">
              {`${playticoins} Playticoins of type "${selectedGiftType.name}" were granted successfully to employee ID(s):`}
            </div>
          );
          res.data.forEach((element: GiftSentResponse, index: number) => {
            msg.push(
              <div className="my-3" key={`msg_product_${index}`}>
                <strong className="font-bold">{element.giftReceiverId}</strong>
              </div>
            );
          });
        }
        setGivePlayticoinsMsg(msg);
        setPlayticoins(0);
        setSelectedGiftType({} as GiftType);
        setShowPlayticoinsGivenPopup(true);
      },
      () => {
        toast.error('Gifts are not successfuly given');
      }
    );
  };
  return (
    <div>
      <div className="mb-10" data-testid="givePlayticoins">
        <div className="inline-block mb-5">
          <span className="mr-5">Give </span>
          <div className="inline-block mr-10">
            <input
              data-testid="give-playticoins"
              className="input-text"
              type="number"
              value={playticoins}
              onChange={e => {
                setPlayticoins(parseInt(e.target.value));
              }}
            />
          </div>
        </div>
        <div className="inline-block mb-5">
          <span className="mr-5">PlaytiCoins of Type: </span>
          <div className="inline-block mr-10 dropdown-minwidth" data-testid="gift-dropdown">
            <DropDown
              value={selectedGiftType.name}
              placeholder="Select type"
              onChange={(v: string) => selectGiftType(giftTypes.find(t => t.name === v))}
              options={giftTypes.map(gift => gift.name)}
            />
          </div>
        </div>
        <div className="inline-block col-span-1">
          <span className="flex items-center justify-center text-center">
            <a
              data-testid="go_give_btn"
              className={`btn-main pl-10 pr-10 ${
                playticoins > 0 && props.usersList.find(user => user.checked) && selectedGiftType.id ? '' : ' disabled'
              }`}
              onClick={() => grantGift()}
            >
              Go
            </a>
          </span>
        </div>
      </div>
      {showPlayticoinsGivenPopup && (
        <ModalDialog
          modalJSX={givePlayticoinsMsg}
          modalTitle={'PLAYTICOINS GRANTING RESULTS'}
          closeShowModal={() => {
            setShowPlayticoinsGivenPopup(false);
          }}
          confirmAction={() => {
            setShowPlayticoinsGivenPopup(false);
          }}
          confirmBtnText={'OK'}
          showCancel={false}
        />
      )}
    </div>
  );
};
export default GivePlayticoins;
