import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { ROUTE_PATHS } from './constants/route_paths';

interface GuardedRouteProps extends RouteProps {
  auth: boolean;
  loginRoute: boolean;
  render: any;
  path: string;
}

//GuaredRoute return an appropriate Route depending of the authantication and logged in status
// if the user is authenticated and hits '/login' route it will redirect the user to the home page
// if the user tries to reach other route that '/login', but is not authenticated, will be redirected to the '/logi'
class GuardedRoute extends Route<GuardedRouteProps> {
  render() {
    return (
      <Route
        path={this.props.path}
        render={(props: RouteComponentProps) => {
          if (!this.props.auth && !this.props.loginRoute) {
            return <Redirect data-testid="redirect" to={ROUTE_PATHS.LOGIN} />;
          }
          if (!this.props.auth && this.props.loginRoute) {
            return <Redirect data-testid="redirect" to={ROUTE_PATHS.HOME} />;
          }
          if (this.props.component) {
            return React.createElement(this.props.component);
          }
          if (this.props.render) {
            return this.props.render(props);
          }
        }}
      />
    );
  }
}
export default GuardedRoute;
