import React, { FunctionComponent } from 'react';
import deleteIcon from '../../assets/images/delete-forever.png';
import editIcon from '../../assets/images/edit.png';
import viewIcon from '../../assets/images/view.png';
import addIcon from '../../assets/images/add.png';
import ellipsisIcon from '../../assets/images/ellipsis.png';
import { CategoryType } from '../../interfaces/category';
import Tooltip from '../shared/Tooltip';

export type CategoryProps = {
  categoryItem: CategoryType;
  treeSchema: boolean[];
  setShowDeleteModal: (b: boolean) => void;
  setDeleteItem: (item: CategoryType) => void;
  onEditClick: (item: CategoryType) => void;
  onViewClick: (item: CategoryType) => void;
  onAddClick: (item: CategoryType) => void;
  onEditStatus: (item: CategoryType) => void;
};

const Category: FunctionComponent<CategoryProps> = props => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  //set showTooltip value
  const onOptionsClick = () => {
    setShowTooltip(prevValue => {
      return !prevValue;
    });
  };

  // every category has delete and edit options
  // if the category is of type page content view option will be also available
  const toolTipContent = (
    <div className="flex align-middle">
      {props.categoryItem.pageContent && (
        <a
          className="cursor-pointer flex flex-col justify-center mr-2"
          data-testid="view_btn"
          onClick={() => {
            props.onViewClick(props.categoryItem);
          }}
        >
          <img className="h-5" src={viewIcon} />
        </a>
      )}
      <a
        className="cursor-pointer flex flex-col justify-center"
        data-testid="view_btn"
        onClick={() => {
          props.onAddClick(props.categoryItem);
        }}
      >
        <img className="h-5" src={addIcon} />
      </a>
      <a
        className="px-1 cursor-pointer flex flex-col justify-center"
        data-testid="edit_btn"
        onClick={() => props.onEditClick(props.categoryItem)}
      >
        <img className="h-5" src={editIcon} />
      </a>
      <a
        className="cursor-pointer"
        data-testid="delete_btn"
        onClick={() => {
          props.setShowDeleteModal(true);
          props.setDeleteItem(props.categoryItem);
        }}
      >
        <img className="h-6" src={deleteIcon} />
      </a>
    </div>
  );

  const optionsIcon = (
    <div className="flex align-middle">
      <a
        className="cursor-pointer flex flex-col justify-center"
        data-testid="view_btn"
        onClick={() => {
          onOptionsClick();
        }}
      >
        <img className="h-4" src={ellipsisIcon} />
      </a>
    </div>
  );

  // logic for drawing the tree structure
  let tree: any[] = [];
  if (props.treeSchema.length > 0) {
    for (let i = 0; i < props.treeSchema.length; i++) {
      // draw the element node and stop the drawing here
      if (!props.treeSchema[i] && i === props.treeSchema.length - 1) {
        tree.push(<span key={i} className={`ml-8 w-0.5 h-2/4 bg-black block self-baseline`}></span>);
        tree.push(<span key={i + 500} className={`w-4 h-0.5 mr-2 bg-black block`}></span>);
        // draw the element node and continue with the drawing
      } else if (props.treeSchema[i] && i === props.treeSchema.length - 1) {
        tree.push(<span key={i} className={`ml-8 w-0.5 h-full bg-black block`}></span>);
        tree.push(<span key={i + 500} className={`w-4 h-0.5 mr-2 bg-black block`}></span>);
        // draw a vertical line to connect two tree nodes
      } else if (props.treeSchema[i] && i < props.treeSchema.length - 1) {
        tree.push(<span key={i} className={`ml-8 w-0.5 h-full bg-black block`}></span>);
        // don't draw nothing just shift the element to the right
      } else {
        tree.push(<span key={i + 8000} className={`ml-8 block`}></span>);
      }
    }
  }

  return (
    <div className={` h-12 flex items-center`}>
      {tree}
      <div className={'flex flex-row justify-center items-center'}>
        <div>
          <label className={`checkbox-container justify-start`}>
            <input
              data-testid="chk_active"
              className="mr-4 leading-tight"
              type="checkbox"
              checked={props.categoryItem.active}
              onChange={() => {
                props.onEditStatus(props.categoryItem);
              }}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <span className={`mr-6 ml-6`}>{props.categoryItem.name}</span>
      </div>
      <div className="inline-block fixed-width" data-testid="cms-tooltip">
        <Tooltip title="" position="right" show={true} showChildrenAsContent={true} placeholderElement={optionsIcon}>
          {toolTipContent}
        </Tooltip>
      </div>
    </div>
  );
};

export default Category;
